import clsx from 'clsx';
import React from 'react';
import './TooltipCardContainer.scss';

export interface TooltipCardContainerProps {
  className?: string;
}

export function TooltipCardContainer({ children, className }: React.PropsWithChildren<TooltipCardContainerProps>) {
  return (
    <div className={clsx('tooltip-card', className)}>
      <div className="tooltip-card-content">{children}</div>
    </div>
  );
}
