import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod, Status } from '@vision/ui/enums';
import { ContentCategory } from '../interfaces';
import { createHttpExtraOptions, insertIfObject } from '../utils';
import { axiosBaseQuery } from './base.service';

export const ContentCategoriesService = createApi({
  reducerPath: 'ContentCategoriesService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getContentCategories: builder.query<ContentCategory[], { accountId: string; status?: Status }>({
      query: ({ accountId, status }) => ({
        url: `/v1/accounts/${accountId}/content_categories`,
        method: HttpMethod.GET,
        params: {
          ...insertIfObject(!!status, { status }),
        },
      }),
    }),
    updateContentCategory: builder.mutation<
      ContentCategory,
      { accountId: string; id: string; data: Partial<ContentCategory> }
    >({
      query: ({ accountId, id, data }) => ({
        url: `/v1/accounts/${accountId}/content_categories/${id}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
            translationMessageKey: 'http.message.updateContentCategory.{{status}}',
          },
        }),
      }),
    }),
  }),
});

export const { useGetContentCategoriesQuery, useUpdateContentCategoryMutation } = ContentCategoriesService;
