import { ActionIcon, ActionIconProps, Tooltip, TooltipProps } from '@mantine/core';
import React from 'react';

export interface VActionIconProps
  extends ActionIconProps,
    Omit<React.HTMLAttributes<HTMLButtonElement>, 'color' | 'style'> {
  tooltipLabel?: React.ReactNode;
  tooltipDisabled?: boolean;
  tooltipOptions?: Omit<TooltipProps, 'disabled' | 'label' | 'children'>;
}

export const VActionIcon = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<VActionIconProps>>(
  ({ children, tooltipLabel, tooltipDisabled, tooltipOptions, ...props }, ref) => {
    return (
      <Tooltip label={tooltipLabel} disabled={tooltipDisabled || !tooltipLabel} {...tooltipOptions}>
        <ActionIcon ref={ref} {...props}>
          {children}
        </ActionIcon>
      </Tooltip>
    );
  },
);
