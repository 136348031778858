import React from 'react';

interface CustomIconTextAnalysisProps extends React.SVGProps<SVGSVGElement> {}

export function CustomIconTextAnalysis({ height, width, ...props }: CustomIconTextAnalysisProps) {
  return (
    <svg
      width={width || 19}
      height={height || 19}
      fill="none"
      viewBox="0 0 19 19"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.54199 7.67578C5.54199 7.2082 5.91816 6.83203 6.38574 6.83203H13.4482C13.9158 6.83203 14.292 7.2082 14.292 7.67578C14.292 8.14336 13.9158 8.51953 13.4482 8.51953H6.38574C5.91816 8.51953 5.54199 8.14336 5.54199 7.67578ZM5.54199 11.3008C5.54199 10.8332 5.91816 10.457 6.38574 10.457H10.4482C10.9158 10.457 11.292 10.8332 11.292 11.3008C11.292 11.7684 10.9158 12.1445 10.4482 12.1445H6.38574C5.91816 12.1445 5.54199 11.7684 5.54199 11.3008Z"
        fill="currentColor"
      />
      <path
        d="M13.9062 1.83203V1.83203C16.1154 1.83203 17.9063 3.62289 17.9063 5.83203V5.83203"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
      />
      <path
        d="M5.69824 16.832V16.832C3.4891 16.832 1.69824 15.0412 1.69824 12.832V12.832"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
      />
      <path
        d="M5.69824 1.83203V1.83203C3.4891 1.83203 1.69824 3.62289 1.69824 5.83203V5.83203"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
      />
      <path
        d="M13.9062 16.832V16.832C16.1154 16.832 17.9063 15.0412 17.9063 12.832V12.832"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
      />
    </svg>
  );
}
