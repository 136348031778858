import { ReportChartConfiguration } from '@vision/ui/interfaces';
import { array, string } from 'yup';
import { HeatMapChart1CompareRenderer } from '../../components';
import {
  DefaultReportChartThemeFont,
  extendDefaultChartValidationSchema,
  GRAPHIC_VISUAL_MAP_DEFAULT_RANGE_COLORS,
} from '../default-chart.config';
import { HeatMapChart1Measures } from './heat-map-chart1.measures';

export const HeatMapChart1DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema(
    {
      selectedCompare: array()
        .min(2, 'validation:rules.reports.selectedCompare.minTwo')
        .max(2, 'validation:rules.reports.selectedCompare.maxTwo')
        .of(string().required('validation:rules.generic.required'))
        .required('validation:rules.generic.required'),
    },
    true,
  ),
  measures: HeatMapChart1Measures,
  compareRendererComponent: HeatMapChart1CompareRenderer,
  compareMultiple: true,
  customize: {
    active: (values) => !!values.selectedMeasure,
    transactionalDate: {
      active: false,
    },
    limits: {
      upperLimitActive: false,
      upperLimit: 0,
      lowerLimitActive: false,
      lowerLimit: 0,
    },
  },
  defaultTheme: {
    active: (values) => !!values.selectedMeasure,
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    xAxis: {
      ...DefaultReportChartThemeFont,
    },
    yAxis: {
      ...DefaultReportChartThemeFont,
    },
    dataLabels: {
      ...DefaultReportChartThemeFont,
      active: false,
      alignment: 'horizontal',
      displayFormat: 'auto',
      hideFormatPosition: true,
    },
    visualMap: {
      active: false,
      inRange: {
        defaultColor: 'THEME_1',
        customize: false,
        color: GRAPHIC_VISUAL_MAP_DEFAULT_RANGE_COLORS.THEME_1,
      },
    },
  },
};
