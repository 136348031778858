import { ComboboxItem, isOptionsGroup } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import type { BaseQueryFn, TypedUseLazyQuery } from '@reduxjs/toolkit/query/react';
import { ApiResponse, AxiosRequestConfigExtended, OptionsGroup } from '@vision/ui/interfaces';
import { ensureArray, filterUniqueItems, insertIfObject, uniqueArray } from '@vision/ui/utils';
import type { AxiosResponse } from 'axios';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { useDeepCompareEffect, useEffectOnce, useFirstMountState } from 'react-use';

const uniqueComboBoxOrOptionsGroupArray = (items: Array<ComboboxItem | OptionsGroup>) => {
  if (items.length > 0) {
    const firstItem = items[0];
    const isGroup = isOptionsGroup(firstItem);

    if (isGroup) {
      return items;
    } else {
      const comboboxItemArray = items as Array<ComboboxItem>;
      const uniqueItems = Array.from(new Map(comboboxItemArray.map((item) => [item.value, item])).values());
      return uniqueItems;
    }
  }

  return items;
};

type SingleSelectItemMapper<ResponseType> = (item: ResponseType) => ComboboxItem | OptionsGroup;
type MultiSelectItemMapper<ResponseType> = (items: ResponseType[]) => Array<ComboboxItem | OptionsGroup>;
type LazyLoadResponseType<ResponseType> = Array<ResponseType & { id: string }>;

export interface UseLazyLoadSelectOptions<RequestType, ResponseType> {
  searchKey?: string;
  filterKey?: string;
  apiRequestParameters?: Omit<RequestType, 'query'>;
  disabledCondition?: (item: ComboboxItem) => boolean;
  multiSelectItemMapper?: MultiSelectItemMapper<ResponseType>;
  singleSelectItemMapper?: SingleSelectItemMapper<ResponseType>;
  useLazyApiQueryFunction: TypedUseLazyQuery<
    ApiResponse<LazyLoadResponseType<ResponseType>>,
    RequestType,
    BaseQueryFn<AxiosRequestConfigExtended, unknown, AxiosResponse<any, any>>
  >;
  defaultValues?: string | string[];
  resetChangeRequestParameters?: boolean;
  onDefaultValuesLoaded?: (data: ResponseType[]) => void;
}

export function useLazyLoadSelect<RequestType, ResponseType>({
  searchKey = 'name',
  filterKey = 'ids',
  apiRequestParameters,
  disabledCondition,
  multiSelectItemMapper,
  singleSelectItemMapper,
  useLazyApiQueryFunction,
  defaultValues,
  resetChangeRequestParameters = false,
  onDefaultValuesLoaded,
}: UseLazyLoadSelectOptions<RequestType, ResponseType>) {
  const isFirstMount = useFirstMountState();
  const [data, setData] = useState<Array<ComboboxItem | OptionsGroup>>([]);
  const [allData, setAllData] = useState<LazyLoadResponseType<ResponseType>>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [allPagesLoaded, setAllPagesLoaded] = useState(false);
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 300);

  const [getQueryFn, { data: queryResponse, isFetching: isLoading }] = useLazyApiQueryFunction();
  const [getQueryDataFn, { data: queryDataResponse, isFetching: isDefaultDataLoading }] = useLazyApiQueryFunction();
  const [getQueryFilterFn, { data: queryFilteredResponse, isFetching: isFilterQueryLoading }] =
    useLazyApiQueryFunction();
  const [isLoadingDefaultValues, setIsLoadingDefaultValues] = useState(true);

  const handleMapSelectItems = (response: LazyLoadResponseType<ResponseType>) => {
    if (singleSelectItemMapper) {
      return response.map(singleSelectItemMapper);
    }

    if (multiSelectItemMapper) {
      return multiSelectItemMapper(response);
    }

    // Default mapper
    return response.map((item) => ({
      value: item.id,
      label: item.id,
    }));
  };

  const allDataSelectItems = useMemo(() => handleMapSelectItems(allData), [allData]);

  const dataSelectItemsWithDisabledCondition: Array<ComboboxItem | OptionsGroup> = useMemo(() => {
    if (disabledCondition) {
      return data.map((item) => {
        const isGroup = isOptionsGroup(item);
        if (isGroup) {
          return {
            ...item,
            items: ensureArray(item.items).map((child) => ({
              ...child,
              disabled: disabledCondition(child) ?? false,
            })),
          };
        }
        return {
          ...item,
          disabled: disabledCondition(item) ?? false,
        };
      });
    }

    return data;
  }, [disabledCondition, data]);

  const handleLoadMore = () => {
    if (searchQuery) {
      if (queryFilteredResponse?.meta?.pagination?.current === queryFilteredResponse?.meta?.pagination?.total_pages) {
        return;
      }
      const currentPage = queryFilteredResponse?.meta?.pagination?.current ?? 1;
      handleLoadMoreFilterRequest(currentPage + 1);
    } else {
      if (queryResponse?.meta?.pagination?.current === queryResponse?.meta?.pagination?.total_pages) {
        return;
      }
      const currentPage = queryResponse?.meta?.pagination?.current ?? 1;
      handleLoadMoreQueryRequest(currentPage + 1);
    }
  };

  const handleLoadMoreQueryRequest = (page: number) => {
    getQueryFn({
      query: qs.stringify({
        page,
        per_page: 20,
      }),
      ...apiRequestParameters,
    } as RequestType);
  };

  const handleLoadMoreFilterRequest = (page: number) => {
    getQueryFilterFn({
      query: qs.stringify({
        page,
        per_page: 20,
        ...insertIfObject(!!searchQuery, {
          [searchKey]: searchQuery,
        }),
      }),
      ...apiRequestParameters,
    } as RequestType);
  };

  const handleGetData = (value: Array<string> | string) => {
    setIsLoadingDefaultValues(true);
    getQueryDataFn({
      query: qs.stringify({
        [filterKey]: ensureArray(value).join(','),
        per_page: 50,
      }),
      ...apiRequestParameters,
    } as RequestType);
  };

  const handleSearch = (query: string) => {
    // If there is no query or all pages are loaded, do not send a request to the API, the Select component can already search for the value written within itself.
    if (!query || allPagesLoaded) {
      // If there is no filtering, show all the data retrieved so far
      setData(allDataSelectItems);
      return;
    }

    getQueryFilterFn({
      query: qs.stringify({
        page: 1,
        per_page: 20,
        ...insertIfObject(!!query, {
          [searchKey]: query,
        }),
      }),
      ...apiRequestParameters,
    } as RequestType);
  };

  const contextValue = useMemo(
    () => ({
      loadMore: handleLoadMore,
      currentPage: queryResponse?.meta?.pagination?.current,
      totalPage: queryResponse?.meta?.pagination?.total_pages,
    }),
    [handleLoadMore, queryResponse],
  );

  useEffect(() => {
    if (!queryResponse) {
      return;
    }

    // If there is no next page, all pages have been fetched
    if (!queryResponse.meta.pagination.next) {
      setAllPagesLoaded(true);
    }

    // Filter the received data and if it already exists, fetch the next page
    const list = filterUniqueItems(handleMapSelectItems(queryResponse.data), data);

    // If there is no data in the received items, fetch the next page
    if (list.length > 0) {
      // Store the received data in a separate "allData" value, used to display the previously fetched data when the filter is removed
      setAllData((prev) => uniqueArray(prev.concat(queryResponse.data), 'id'));
      setData((prev) => prev.concat(list));
    } else if (queryResponse.meta.pagination.next && !allPagesLoaded) {
      handleLoadMoreQueryRequest(queryResponse.meta.pagination.current + 1);
    }
  }, [queryResponse]);

  useEffect(() => {
    if (!queryFilteredResponse) {
      return;
    }

    const list = handleMapSelectItems(queryFilteredResponse.data);

    setAllData((prev) => uniqueArray(prev.concat(queryFilteredResponse.data), 'id'));
    setData((prev) => uniqueComboBoxOrOptionsGroupArray(prev.concat(filterUniqueItems(list, data))));
  }, [queryFilteredResponse]);

  useEffect(() => {
    if (!queryDataResponse) {
      return;
    }

    const list = handleMapSelectItems(queryDataResponse.data);
    // If there are defaultValues in the queryDataResponse data, send them to the top
    if (defaultValues && defaultValues.length > 0 && onDefaultValuesLoaded) {
      onDefaultValuesLoaded(queryDataResponse.data);
    }

    setIsLoadingDefaultValues(false);
    setAllData((prev) => uniqueArray(prev.concat(queryDataResponse.data), 'id'));
    setData((prev) => uniqueComboBoxOrOptionsGroupArray(prev.concat(filterUniqueItems(list, data))));
  }, [queryDataResponse]);

  useEffect(() => {
    handleSearch(debouncedSearchQuery);
  }, [debouncedSearchQuery]);

  const fetchInitialData = (values: Array<string> | string) => {
    // When the component is mounted, fetch the first page
    handleLoadMoreQueryRequest(1);

    // If there are default values, fetch the data from the API
    if (values && values.length > 0) {
      setIsLoadingDefaultValues(true);
      handleGetData(values);
    } else {
      setIsLoadingDefaultValues(false);
    }
  };

  useEffectOnce(() => {
    fetchInitialData(defaultValues);
  });

  useEffect(() => {
    if (isLoadingDefaultValues) {
      return;
    }
    // If the defaultValues are not in dataSelectItemsWithDisabledCondition, request the missing ones
    const missingDefaultValues = ensureArray(defaultValues).filter(
      (value) => !dataSelectItemsWithDisabledCondition.some((item) => 'value' in item && item.value === value),
    );

    if (missingDefaultValues.length > 0) {
      setIsLoadingDefaultValues(true);
      handleGetData(missingDefaultValues);
    }
  }, [defaultValues, isLoadingDefaultValues]);

  useDeepCompareEffect(() => {
    // When the parameters change, the requests should start from scratch. This is optional.
    if (resetChangeRequestParameters && !isFirstMount) {
      // defaultValues are constant. They should not be deleted.
      setAllData([]);
      setData([]);
      fetchInitialData(defaultValues);
      setAllPagesLoaded(false);
    }
  }, [resetChangeRequestParameters, apiRequestParameters]);

  return {
    allData,
    allDataSelectItems,
    contextValue,
    dataSelectItemsWithDisabledCondition,
    isFilterQueryLoading,
    isLoading,
    setSearchQuery,
    allPagesLoaded,
    isDefaultDataLoading,
    isLoadingDefaultValues,
  };
}
