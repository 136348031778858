import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  ApiBaseRequest,
  ApiResponse,
  IntelligentTag,
  TagIntelligentTag,
  TagIntelligentTagRequest,
} from '@vision/ui/interfaces';
import { buildEndpointWithQuery, createHttpExtraOptions, insertIfObject } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const IntelligentTagService = createApi({
  reducerPath: 'IntelligentTagService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    fetchIntelligentTagsWithPagination: builder.query<ApiResponse<IntelligentTag[]>, ApiBaseRequest>({
      query: ({ nodeId, query, sortBy, sortOrder }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/intelligent_tags`, query),
        method: HttpMethod.GET,
        params: {
          ...insertIfObject(!!sortBy, {
            sort_by: sortBy,
          }),
          ...insertIfObject(!!sortOrder, {
            sort_order: sortOrder,
          }),
        },
      }),
    }),
    updateIntelligentTag: builder.mutation<
      ApiResponse<IntelligentTag>,
      { nodeId: string; id: string; data: { name?: string; is_negative?: boolean } }
    >({
      query: ({ nodeId, id, data }) => ({
        url: `/v2/accounts/${nodeId}/intelligent_tags/${id}`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.intelligentTag.updateIntelligentTag.{{status}}',
          },
        }),
      }),
    }),
    deleteIntelligentTag: builder.mutation<ApiResponse<IntelligentTag>, { nodeId: string; id: string }>({
      query: ({ nodeId, id }) => ({
        url: `/v2/accounts/${nodeId}/intelligent_tags/${id}/delete`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.intelligentTag.deleteIntelligentTag.{{status}}',
          },
        }),
      }),
    }),
    fetchTagIntelligentTags: builder.query<ApiResponse<TagIntelligentTag[]>, { nodeId: string; query: string }>({
      query: ({ nodeId, query }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/tag_intelligent_tags`, query),
        method: HttpMethod.GET,
      }),
    }),
    createTagIntelligentTag: builder.mutation<
      ApiResponse<TagIntelligentTag>,
      { nodeId: string; data: TagIntelligentTagRequest }
    >({
      query: ({ nodeId, data }) => ({
        url: `/v2/accounts/${nodeId}/tag_intelligent_tags`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.tagIntelligentTag.createTagIntelligentTag.{{status}}',
          },
        }),
      }),
    }),
    getTagIntelligentTag: builder.query<ApiResponse<TagIntelligentTag>, { nodeId: string; id: string }>({
      query: ({ nodeId, id }) => ({
        url: `/v2/accounts/${nodeId}/tag_intelligent_tags/${id}`,
        method: HttpMethod.GET,
      }),
    }),
    destroyTagIntelligentTag: builder.mutation<ApiResponse<TagIntelligentTag>, { nodeId: string; id: string }>({
      query: ({ nodeId, id }) => ({
        url: `/v2/accounts/${nodeId}/tag_intelligent_tags/${id}/delete`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.tagIntelligentTag.destroyTagIntelligentTag.{{status}}',
          },
        }),
      }),
    }),
    updateTagIntelligentTag: builder.mutation<
      ApiResponse<TagIntelligentTag>,
      { nodeId: string; id: string; data: TagIntelligentTagRequest }
    >({
      query: ({ nodeId, id, data }) => ({
        url: `/v2/accounts/${nodeId}/tag_intelligent_tags/${id}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.tagIntelligentTag.updateTagIntelligentTag.{{status}}',
          },
        }),
      }),
    }),
    importTagIntelligentTags: builder.mutation<void, { file: FormData; nodeId: string }>({
      query: ({ file, nodeId }) => ({
        url: `/v2/accounts/${nodeId}/tag_intelligent_tags/import`,
        method: HttpMethod.POST,
        data: file,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.tagIntelligentTag.importTagIntelligentTags.{{status}}',
          },
        }),
      }),
    }),
  }),
});

export const {
  useLazyFetchIntelligentTagsWithPaginationQuery,
  useUpdateIntelligentTagMutation,
  useDeleteIntelligentTagMutation,
  useLazyFetchTagIntelligentTagsQuery,
  useCreateTagIntelligentTagMutation,
  useLazyGetTagIntelligentTagQuery,
  useDestroyTagIntelligentTagMutation,
  useUpdateTagIntelligentTagMutation,
  useImportTagIntelligentTagsMutation,
} = IntelligentTagService;
