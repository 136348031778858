import { ChannelType } from '@vision/ui/enums';
import { convertEnumToSelectItems } from '@vision/ui/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useChannelTypes(types?: ChannelType[]) {
  const { i18n, t } = useTranslation();

  return useMemo(() => {
    const items = convertEnumToSelectItems(ChannelType, (item) => ({
      label: t(`channelTypes.${item.value}`),
      value: item.value,
    }));

    if (types?.length) {
      return items.filter((item) => types.includes(item.value as ChannelType));
    }

    return items;
  }, [i18n.language, types]);
}

export function useChannelTypesWithout(types?: ChannelType[]): ChannelType[] {
  const channelTypes = useChannelTypes(types);

  return channelTypes
    .filter((item) => !types?.includes(item.value as ChannelType))
    .map((item) => item.value as ChannelType);
}
