import { Group, GroupProps, Text } from '@mantine/core';
import clsx from 'clsx';
import classes from './ActionPanelHeader.module.scss';

interface ActionPanelHeaderProps extends Omit<GroupProps, 'title'> {
  title: React.ReactNode;
}

export function ActionPanelHeader({
  children,
  title,
  className,
  ...props
}: React.PropsWithChildren<ActionPanelHeaderProps>) {
  return (
    <Group
      className={clsx(classes.actionPanelHeader, className)}
      gap={32}
      justify="space-between"
      wrap="nowrap"
      {...props}
    >
      {typeof title === 'string' ? <Text component="span">{title}</Text> : title}
      {children}
    </Group>
  );
}
