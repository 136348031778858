import { HoverCard, HoverCardProps } from '@mantine/core';
import { ForwardRefWithStaticComponents } from '@vision/ui/interfaces';
import clsx from 'clsx';
import React, { forwardRef, useEffect, useState } from 'react';
import classes from './TooltipCard.module.scss';
import { TooltipCardContainer, TooltipCardContainerProps } from './TooltipCardContainer';

interface TooltipCardProps extends HoverCardProps {
  width?: number;
  id?: string;
  withinAnimation?: boolean;
  className?: string;
  zIndex?: number;
}

const _TooltipCard = forwardRef<HTMLDivElement, React.PropsWithChildren<TooltipCardProps>>(
  ({ children, width, position, disabled, withinAnimation, className, zIndex, ...props }, ref) => {
    const [activeAnimation, setActiveAnimation] = useState(withinAnimation);
    const [shouldClose, setShouldClose] = useState(false);

    useEffect(() => {
      if (shouldClose) {
        setActiveAnimation(false);
        setShouldClose(false);
      }
    }, [shouldClose]);

    const handleClose = () => {
      setShouldClose(true);
    };

    return (
      <div ref={ref} {...props} className={className}>
        <HoverCard
          withArrow={true}
          arrowRadius={3}
          arrowSize={10}
          arrowOffset={15}
          position={position}
          zIndex={zIndex}
          disabled={disabled}
          keepMounted={withinAnimation}
          withinPortal={true}
          width={width}
          initiallyOpened={withinAnimation}
          classNames={{
            dropdown: clsx({ [classes.shakeAnimation]: activeAnimation }),
          }}
          onClose={handleClose}
        >
          {children}
        </HoverCard>
      </div>
    );
  },
) as any;

function TooltipCardTarget({ children }: React.PropsWithChildren) {
  return <HoverCard.Target>{children}</HoverCard.Target>;
}

function TooltipCardDropdown({ children, className }: React.PropsWithChildren<TooltipCardContainerProps>) {
  return (
    <HoverCard.Dropdown className={clsx(classes.tooltipCardDropdown, className)}>
      <TooltipCardContainer>{children}</TooltipCardContainer>
    </HoverCard.Dropdown>
  );
}

_TooltipCard.Target = TooltipCardTarget;
_TooltipCard.Dropdown = TooltipCardDropdown;

export const TooltipCard: ForwardRefWithStaticComponents<
  HTMLDivElement,
  TooltipCardProps,
  { Target: typeof TooltipCardTarget; Dropdown: typeof TooltipCardDropdown }
> = _TooltipCard;
