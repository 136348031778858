import {
  ReportChartGroupCompareItemType,
  ReportChartGroupItemType,
  ReportFeedbackStaticResponseItem,
} from '@vision/ui/interfaces';

export function isReportFeedbackStaticResponseItem(obj: any): obj is ReportFeedbackStaticResponseItem {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    (obj.jobId === null || typeof obj.jobId === 'string') &&
    typeof obj.data === 'object' && // Burada ReportFeedbackResponseCollection tipine daha spesifik bir kontrol ekleyebilirsin.
    typeof obj.measure === 'string'
  );
}

export function isReportFeedbackStaticResponseItemArray(obj: any): obj is ReportFeedbackStaticResponseItem[] {
  return Array.isArray(obj) && obj.every(isReportFeedbackStaticResponseItem);
}

export function reportWidgetHasContentCategoryCompare(
  widgetType: ReportChartGroupItemType,
  compares: ReportChartGroupCompareItemType[],
) {
  return (
    compares.includes('category-content-name') && widgetType !== 'tree-map-chart-2' && widgetType !== 'tree-map-chart-1'
  );
}
