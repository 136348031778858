import { ReportChartConfiguration, ReportChartFilterItemTypes, ReportChartMeasureType } from '@vision/ui/interfaces';
import { array, object, string, TestContext } from 'yup';
import { TableChart2MeasureRenderer } from '../../components';
import { TableChart2CompareRenderer } from '../../components/ReportsDetailGraphicChartsRenderer/ReportsDetailGraphicChartsCompareRenderer/TableChart2CompareRenderer';
import {
  DefaultReportChartCompareSchema,
  DefaultReportChartThemeFont,
  extendDefaultChartValidationSchema,
} from '../default-chart.config';
import { TableChart2Filtres } from './table-chart2.filtre';
import { TableChart2Measures } from './table-chart2.measures';

export const TableChart2DefaultConfiguration: ReportChartConfiguration = {
  validationSchema: extendDefaultChartValidationSchema(
    {
      selectedCompareCriteria: object().shape({
        ...DefaultReportChartCompareSchema,
      }),
      selectedCompare: array()
        .of(string())
        .when('selectedMeasure', {
          is: (value: string) => !['matrix-question-csat-overview', 'matrix-question-response-count'].includes(value),
          then: (field) => field.min(1, 'validation:rules.generic.required'),
        }),
      selectedPeriodAndValueComparison: array()
        .of(string())
        .when('selectedMeasure', {
          is: (value: string) => value === 'overview-compared',
          then: (field) => field.min(0, 'validation:rules.generic.required'),
        }),
      selectedFilters: object().shape({
        selectedQuestionIds: array()
          .of(string())
          .test(
            'selectedCompareCriteria',
            'validation:rules.generic.required',
            function (value: any, context: TestContext) {
              const selectedMeasure = context.from[1]?.value['selectedMeasure'];
              if (
                ['matrix-question-csat-overview', 'matrix-question-response-count'].includes(selectedMeasure) &&
                value.length === 0
              ) {
                return false;
              }
              return true;
            },
          ),
      }),
    },
    true,
  ),
  measures: TableChart2Measures,
  compareRendererComponent: TableChart2CompareRenderer,
  measureRendererComponent: TableChart2MeasureRenderer,
  hideCompareCondition: (values) =>
    ['matrix-question-csat-overview', 'matrix-question-response-count'].includes(values.selectedMeasure),
  customize: {
    active: (values) => {
      if (['matrix-question-response-count', 'matrix-question-csat-overview'].includes(values.selectedMeasure)) {
        return values.selectedFilters.selectedQuestionIds.length > 0;
      }

      return values.selectedMeasure && values.selectedCompare.length > 0;
    },
    sortResults: {
      limit: 0,
      order: null,
    },
    limits: {
      upperLimitActive: false,
      upperLimit: 0,
      lowerLimitActive: false,
      lowerLimit: 0,
    },
    transactionalDate: {
      active: false,
    },
  },
  filters: (measureType: ReportChartMeasureType) => {
    const matchingItem = TableChart2Filtres.find((item) => item.measures.includes(measureType));
    return matchingItem ? matchingItem.filterItems : ReportChartFilterItemTypes;
  },
  defaultTheme: {
    active: (values) => {
      if (['matrix-question-response-count', 'matrix-question-csat-overview'].includes(values.selectedMeasure)) {
        return values.selectedFilters.selectedQuestionIds.length > 0;
      }

      return values.selectedMeasure && values.selectedCompare.length > 0;
    },
    title: {
      ...DefaultReportChartThemeFont,
      label: '',
    },
    dataLabels: {
      ...DefaultReportChartThemeFont,
      fontBold: false,
      active: false,
      displayFormat: 'auto',
      position: 'center',
    },
    extraSettings: {
      tableHeader: {
        ...DefaultReportChartThemeFont,
        showConduction(values) {
          return !!values.selectedCompare.length;
        },
      },
    },
  },
  responseMultiple: false,
};
