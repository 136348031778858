import { FileWithPath } from '@mantine/dropzone';
import { findMimeTypeFromValue } from '@vision/ui/constants';
import { DownloadableZipFileAsDataUrl, ExcelCsvSheetParseResult } from '@vision/ui/interfaces';
import qs from 'qs';
import { ensureArray } from './array.utils';

export interface ReadFileContentReturnType {
  asDataURL: () => Promise<string>;
  asText: () => Promise<string>;
}

export function readFileContent(file: File | FileWithPath): ReadFileContentReturnType {
  function readAs(type: 'text' | 'dataURL'): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result.toString());
      };

      reader.onerror = reject;

      switch (type) {
        case 'text':
          reader.readAsText(file);
          break;
        case 'dataURL':
          reader.readAsDataURL(file);
          break;
      }
    });
  }

  return {
    asDataURL: () => readAs('dataURL'),
    asText: () => readAs('text'),
  };
}

export function getExtension(url: string) {
  // Extension starts after the first dot after the last slash
  const extStart = url.indexOf('.', url.lastIndexOf('/') + 1);
  if (extStart === -1) {
    return '';
  }
  const ext = url.substring(extStart + 1);
  // end of extension must be one of: end-of-string or question-mark or hash-mark
  const extEnd = ext.search(/$|[?#]/);
  return ext.substring(0, extEnd);
}

export function parseActiveStorageUrl(url: string) {
  // https://psncontent.blob.core.windows.net/voice/variants/1558f0f1-560a-463b-9156-9b538d0a4fcf/ad5273770a06e8c2463720baa6af55927eb5a7b9d21dc939274f826408fcb356?sp=r&sv=2017-11-09&se=2023-12-06T07%3A23%3A07Z&rscd=inline%3B+filename%3D%22Black-and-White-Desktop-HD-Wallpaper2.jpg%22%3B+filename*%3DUTF-8%27%27Black-and-White-Desktop-HD-Wallpaper2.jpg&rsct=image%2Fjpeg&sr=b&sig=DcQXKQse90tCesO4wzHkRXRN9DwybOlWNxgVXmGM28E%3D

  const parsed = qs.parse(new URLSearchParams(url).toString());
  const found = ensureArray((parsed.rscd as string).replaceAll(' ', '').split(';')).find((item) =>
    item.includes('filename='),
  );
  const fileName = found.replaceAll('"', '');
  const fileExtension = getExtension(fileName) || (parsed.rsct as string).split('/').pop();
  return {
    fileName,
    fileExtension,
  };
}

export function truncateFileNameOrUrl(fileNameOrUrl: string, maxLength: number) {
  const fileNameWithoutQueryParams = fileNameOrUrl.split('?')[0];
  let ext = getExtension(fileNameWithoutQueryParams);
  if (!ext) {
    ext = parseActiveStorageUrl(fileNameOrUrl).fileExtension;
  }

  const name = fileNameOrUrl.replace(`.${ext}`, '');

  if (name.length > maxLength) {
    const half = maxLength / 2;
    const firstPart = name.slice(0, half);
    const lastPart = name.slice(-5);
    return `${firstPart}...${lastPart}.${ext}`;
  }
  return `${name}.${ext}`;
}

export function createFileExtensionsText(accept: string[]) {
  // [csv, jpeg]
  const extensions = accept
    .map((item) => findMimeTypeFromValue(item)?.key)
    .filter(Boolean)
    .join(', ');

  return `[${extensions}]`;
}

export function normalizeCsvOrExcelRows(result: string[][]): ExcelCsvSheetParseResult {
  const [headers, ...data] = result;
  const maxColumnLength = (headers as string[]).length;
  const patchedData = data.map((list) => {
    if (list.length < maxColumnLength) {
      return [...list, ...Array.from({ length: maxColumnLength - list.length }).fill('')] as string[];
    }
    return list;
  });
  return {
    headers,
    rows: patchedData,
  };
}

export function getFileExtension(file: File | FileWithPath) {
  if (!file) {
    return;
  }

  return file.name.split('.').pop();
}

export function isExcelFile(file: File | FileWithPath) {
  const ext = getFileExtension(file);

  return ext.includes('xls');
}

export async function downloadFile(name: string, content: Blob) {
  const fileSaver = await import('file-saver');

  return fileSaver.saveAs(content, name);
}

export async function downloadFilesAsZip(zipFileName: string, files: DownloadableZipFileAsDataUrl[]) {
  const { default: JSZip } = await import('jszip');
  const zip = new JSZip();

  files.forEach((item) => {
    const contentIndex = item.content.indexOf('base64,') + 'base64,'.length;
    zip.file(item.name, item.content.substring(contentIndex), {
      base64: true,
    });
  });

  const result = await zip.generateAsync({
    type: 'blob',
  });

  return downloadFile(zipFileName, result);
}

export function isFileEmpty(file: File | FileWithPath) {
  if (!file) {
    return true;
  }

  return file.size === 0;
}

export function downloadFileFromUrl(url: string, name: string) {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
