import { Box, BoxProps } from '@mantine/core';
import clsx from 'clsx';
import React from 'react';
import classes from './ActionPanel.module.scss';
import { ActionPanelBody, ActionPanelFooter, ActionPanelHeader } from './components';

interface ActionPanelProps extends BoxProps {}

export function ActionPanel({ children, className, ...props }: React.PropsWithChildren<ActionPanelProps>) {
  return (
    <Box className={clsx(classes.actionPanel, className)} {...props}>
      {children}
    </Box>
  );
}

ActionPanel.Header = ActionPanelHeader;
ActionPanel.Body = ActionPanelBody;
ActionPanel.Footer = ActionPanelFooter;
