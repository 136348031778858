import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod, ReportType } from '@vision/ui/enums';
import {
  ApiResponse,
  CopyReportRequest,
  CreateNewReportGraphicRequest,
  CreateNewReportRequest,
  HttpBaseRequest,
  ReportDetail,
  ReportFavoriteDetail,
  ReportPermissions,
  ReportWidget,
  ShareReportByEmailRequest,
  UpdateReportsPermissionsRequest,
  User,
  UserGroup,
} from '@vision/ui/interfaces';
import { buildEndpointWithQuery, createHttpExtraOptions, sortByNameAlphabetical } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const ReportsService = createApi({
  reducerPath: 'ReportsService',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['GetReports', 'GetFavoriteReports', 'GetReportGraphicDetail'],
  endpoints: (builder) => ({
    createNewReport: builder.mutation<ApiResponse<ReportDetail>, CreateNewReportRequest>({
      query: (data) => ({
        url: `/v2/accounts/${data.node_id}/dashboards`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
            translationMessageKey: 'http.message.reports.createNewReport.{{status}}',
          },
        }),
      }),
    }),
    deleteReport: builder.mutation<ApiResponse<ReportDetail>, { nodeId: string; reportId: string }>({
      query: ({ nodeId, reportId }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/delete`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.reports.deleteReport.{{status}}',
          },
        }),
      }),
      invalidatesTags: ['GetReports', 'GetFavoriteReports'],
    }),
    copyReport: builder.mutation<
      ApiResponse<ReportDetail>,
      { nodeId: string; reportId: string; data: CopyReportRequest }
    >({
      query: ({ nodeId, reportId, data }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/copy`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
            translationMessageKey: 'http.message.reports.copyReport.{{status}}',
          },
        }),
      }),
      invalidatesTags: ['GetReports'],
    }),
    shareReportByEmail: builder.mutation<
      ApiResponse<ReportDetail>,
      { nodeId: string; reportId: string; data: ShareReportByEmailRequest }
    >({
      query: ({ nodeId, reportId, data }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/email_sharing`,
        method: HttpMethod.POST,
        data,
      }),
    }),
    getReports: builder.query<ApiResponse<ReportDetail[]>, { nodeId: string; query?: string }>({
      query: ({ nodeId, query }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/dashboards`, query),
        method: HttpMethod.GET,
        keepUnusedDataFor: 0,
      }),
      providesTags: ['GetReports'],
    }),
    getReportDetail: builder.query<ApiResponse<ReportDetail>, { nodeId: string; reportId: string }>({
      query: ({ nodeId, reportId }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}`,
        method: HttpMethod.GET,
      }),
    }),
    getReportPermissions: builder.query<ApiResponse<ReportPermissions>, { nodeId: string; reportId: string }>({
      query: ({ nodeId, reportId }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/permissions`,
        method: HttpMethod.GET,
      }),
    }),
    updateReportPermissions: builder.mutation<
      ApiResponse<ReportPermissions>,
      { nodeId: string; reportId: string; data: UpdateReportsPermissionsRequest }
    >({
      query: ({ nodeId, reportId, data }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/permissions`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.reports.updateReportPermissions.{{status}}',
          },
        }),
      }),
    }),
    getReportsUserGroups: builder.query<
      ApiResponse<UserGroup[]>,
      HttpBaseRequest<{ nodeId: string; includeSelf?: boolean }>
    >({
      query: ({ nodeId, includeSelf = false }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/user_groups`,
        method: HttpMethod.GET,
        params: {
          include_self: includeSelf,
        },
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleSuccess: false,
          },
        }),
      }),
      transformResponse: (response: ApiResponse<UserGroup[]>) => {
        return { ...response, data: sortByNameAlphabetical(response.data) };
      },
    }),
    getReportUsers: builder.query<
      ApiResponse<User[]>,
      HttpBaseRequest<{ role?: string; nodeId?: string; query?: string }>
    >({
      query: ({ nodeId, query }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/dashboards/users`, query),
        method: HttpMethod.GET,
      }),
      transformResponse: (response: ApiResponse<User[]>) => {
        return { ...response, data: sortByNameAlphabetical(response.data) };
      },
    }),
    toggleReportFavorite: builder.mutation<
      ApiResponse<ReportDetail>,
      { nodeId: string; reportId: string; isFavorite: boolean }
    >({
      query: ({ nodeId, reportId, isFavorite }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/favorite`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: `http.message.reports.${isFavorite ? 'addReportFavorite' : 'removeReportFavorite'}.{{status}}`,
          },
        }),
      }),
      invalidatesTags: (result, error) => {
        if (!error) {
          return ['GetReports', 'GetFavoriteReports'];
        }
        return [];
      },
    }),
    getFavoriteReports: builder.query<ApiResponse<ReportFavoriteDetail[]>, { nodeId: string }>({
      query: ({ nodeId }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/favorite`,
        method: HttpMethod.GET,
      }),
      providesTags: ['GetFavoriteReports'],
      transformResponse: (response: ApiResponse<ReportFavoriteDetail[]>) => {
        return {
          ...response,
          data: response.data.sort((a, b) => {
            return new Date(b.dashboard.updated_at).getTime() - new Date(a.dashboard.updated_at).getTime();
          }),
        };
      },
    }),
    createNewReportGraphicWithWizard: builder.mutation<
      ApiResponse<ReportDetail>,
      { nodeId: string; reportId: string; data: CreateNewReportGraphicRequest }
    >({
      query: ({ nodeId, reportId, data }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/dashboard_widgets`,
        method: HttpMethod.POST,
        data,
      }),
      invalidatesTags: ['GetFavoriteReports'],
    }),
    getReportGraphicDetail: builder.query<
      ApiResponse<ReportWidget>,
      { nodeId: string; reportId: string; widgetId: string; type: ReportType }
    >({
      query: ({ nodeId, reportId, widgetId, type }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/dashboard_widgets/${widgetId}`,
        method: HttpMethod.GET,
        params: {
          type,
        },
      }),
      providesTags: ['GetReportGraphicDetail'],
    }),
    createNewReportGraphic: builder.mutation<
      ApiResponse<ReportDetail>,
      { nodeId: string; reportId: string; data: ReportWidget }
    >({
      query: ({ nodeId, reportId, data }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/dashboard_widgets`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.reports.createChart.{{status}}',
          },
        }),
      }),
      invalidatesTags: ['GetFavoriteReports'],
    }),
    updateReportGraphic: builder.mutation<
      ApiResponse<ReportDetail>,
      { nodeId: string; reportId: string; data: ReportWidget; widgetId: string }
    >({
      query: ({ nodeId, reportId, data, widgetId }) => ({
        url: `/v2/accounts/${nodeId}/dashboards/${reportId}/dashboard_widgets/${widgetId}/update`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.reports.updateChart.{{status}}',
          },
        }),
      }),
      invalidatesTags: ['GetReportGraphicDetail', 'GetFavoriteReports'],
    }),
  }),
});

export const {
  useCreateNewReportMutation,
  useDeleteReportMutation,
  useCopyReportMutation,
  useShareReportByEmailMutation,
  useLazyGetReportsQuery,
  useGetReportDetailQuery,
  useLazyGetReportPermissionsQuery,
  useUpdateReportPermissionsMutation,
  useGetReportsUserGroupsQuery,
  useGetReportUsersQuery,
  useToggleReportFavoriteMutation,
  useGetFavoriteReportsQuery,
  useCreateNewReportGraphicWithWizardMutation,
  useGetReportGraphicDetailQuery,
  useUpdateReportGraphicMutation,
  useCreateNewReportGraphicMutation,
  useLazyGetReportUsersQuery,
} = ReportsService;
