import { ComboboxItem } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { VMultiSelect, VMultiSelectProps } from '@vision/ui/components';
import { UseLazyLoadSelectOptions, useLazyLoadSelect } from '@vision/ui/hooks';
import { OptionsGroup } from '@vision/ui/interfaces';
import { useTranslation } from 'react-i18next';

interface VLazyLoadMultiSelectProps<RequestType, ResponseType>
  extends Omit<VMultiSelectProps, 'data'>,
    UseLazyLoadSelectOptions<RequestType, ResponseType> {
  onDataChange?: (data: ResponseType[]) => void;
  onSelectItemsChange?: (data: Array<ComboboxItem | OptionsGroup>) => void;
  searchKey?: string;
  showSelectedPillCountText?: boolean;
  resetChangeRequestParameters?: boolean;
}

export function VLazyLoadMultiSelect<RequestType, ResponseType>({
  apiRequestParameters,
  disabledCondition,
  multiSelectItemMapper,
  onDataChange,
  onSelectItemsChange,
  searchable = true,
  singleSelectItemMapper,
  useLazyApiQueryFunction,
  searchKey,
  value,
  filterKey,
  showSelectedPillCountText,
  resetChangeRequestParameters,
  onDefaultValuesLoaded,
  ...props
}: VLazyLoadMultiSelectProps<RequestType, ResponseType>) {
  const { t } = useTranslation();

  const {
    allData,
    allDataSelectItems,
    contextValue: { loadMore },
    dataSelectItemsWithDisabledCondition,
    isFilterQueryLoading,
    isLoading: isLoadingLazyLoadSelect,
    setSearchQuery,
    isLoadingDefaultValues,
  } = useLazyLoadSelect({
    filterKey,
    searchKey,
    apiRequestParameters,
    disabledCondition,
    multiSelectItemMapper,
    singleSelectItemMapper,
    useLazyApiQueryFunction,
    defaultValues: value,
    resetChangeRequestParameters,
    onDefaultValuesLoaded,
  });

  const handleLoadMore = () => {
    loadMore();
  };

  useDidUpdate(() => {
    onDataChange?.(allData);
  }, [allData]);

  useDidUpdate(() => {
    onSelectItemsChange?.(allDataSelectItems);
  }, [allDataSelectItems]);

  const isLoading = isFilterQueryLoading || isLoadingLazyLoadSelect;

  return (
    <VMultiSelect
      value={value}
      data={dataSelectItemsWithDisabledCondition}
      loading={isLoading}
      onScrollToBottom={handleLoadMore}
      onSearchChange={setSearchQuery}
      searchable={searchable}
      withSelectAll={false}
      showAllSelectedPill={false}
      showSelectedPillCountText={showSelectedPillCountText}
      isValuesPillLoading={isLoadingDefaultValues}
      placeholder={isLoadingDefaultValues || isFilterQueryLoading ? `${t('loading')}...` : t('search')}
      {...props}
    />
  );
}
