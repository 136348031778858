import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { AdvancedTextAnalysisLog, AIModel, AIModelUpdateRequest, ApiResponse } from '@vision/ui/interfaces';
import { buildEndpointWithQuery, createHttpExtraOptions, insertIfObject } from '../utils';
import { axiosBaseQuery } from './base.service';

export const AIModelService = createApi({
  reducerPath: 'AIModelService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAIModels: builder.query<
      ApiResponse<AIModel[]>,
      { nodeId: string; query: string; list?: boolean; modelCreation?: boolean }
    >({
      query: ({ nodeId, query, list, modelCreation }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/ai_models`, query),
        method: HttpMethod.GET,
        params: {
          ...insertIfObject(!!list, { list: true }),
          ...insertIfObject(!!modelCreation, { model_creation: true }),
        },
      }),
    }),
    getAIModel: builder.query<ApiResponse<AIModel>, { nodeId: string; id: string }>({
      query: ({ nodeId, id }) => ({
        url: `/v2/accounts/${nodeId}/ai_models/${id}`,
        method: HttpMethod.GET,
      }),
    }),
    createAIModel: builder.mutation<ApiResponse<AIModel>, { nodeId: string; data: FormData }>({
      query: ({ nodeId, data }) => ({
        url: `/v2/accounts/${nodeId}/ai_models`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.aiModel.createAIModel.{{status}}',
          },
        }),
      }),
    }),
    updateAIModel: builder.mutation<ApiResponse<AIModel>, { nodeId: string; id: string; data: AIModelUpdateRequest }>({
      query: ({ nodeId, id, data }) => ({
        url: `/v2/accounts/${nodeId}/ai_models/${id}`,
        method: HttpMethod.POST,
        data,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.aiModel.updateAIModel.{{status}}',
          },
        }),
      }),
    }),
    deleteAIModel: builder.mutation<ApiResponse<AIModel>, { nodeId: string; id: string }>({
      query: ({ nodeId, id }) => ({
        url: `/v2/accounts/${nodeId}/ai_models/${id}/delete`,
        method: HttpMethod.POST,
        extraOptions: createHttpExtraOptions({
          notification: {
            translationMessageKey: 'http.message.aiModel.deleteAIModel.{{status}}',
          },
        }),
      }),
    }),
    getAdvancedTextAnalysisLogs: builder.query<
      ApiResponse<AdvancedTextAnalysisLog[]>,
      { nodeId: string; query: string }
    >({
      query: ({ nodeId, query }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/advanced_text_analysis_logs`, query),
        method: HttpMethod.GET,
      }),
    }),
    getAdvancedTextAnalysisLog: builder.query<ApiResponse<AdvancedTextAnalysisLog>, { nodeId: string; id: string }>({
      query: ({ nodeId, id }) => ({
        url: `/v2/accounts/${nodeId}/advanced_text_analysis_logs/${id}`,
        method: HttpMethod.GET,
      }),
    }),
  }),
});

export const {
  useLazyGetAIModelsQuery,
  useLazyGetAIModelQuery,
  useCreateAIModelMutation,
  useUpdateAIModelMutation,
  useDeleteAIModelMutation,
  useLazyGetAdvancedTextAnalysisLogsQuery,
  useLazyGetAdvancedTextAnalysisLogQuery,
} = AIModelService;
