import { Permission, PermissionItem } from '@vision/ui/interfaces';

export const DEFAULT_PERMISSIONS: Permission = {
  inbox: {
    shortCodes: ['Feedback.index', 'Feedback.show'],
    children: {
      exportFeedback: {
        shortCodes: ['Feedback.export_as_csv'],
      },
      createManualFeedback: {
        shortCodes: ['Feedback.create'],
      },
      importFeedback: {
        shortCodes: ['Feedback.import'],
      },
      replyToCustomer: {
        shortCodes: ['Comment.create_customer_comment'],
      },
      viewQuickResponses: {
        shortCodes: ['QuickResponse.index'],
      },
      publishFeedback: {
        shortCodes: ['Feedback.publish'],
      },
      createCatiCall: {
        shortCodes: ['CatiCall.create', 'CatiCall.update'],
      },
      translateFeedback: {
        shortCodes: ['Feedback.translate'],
      },
      editFeedbackTag: {
        shortCodes: ['FeedbackTag.create', 'FeedbackTag.destroy'],
      },
      maskInbox: {
        shortCodes: ['Feedback.display_masked'],
      },
      configureCatiCall: {
        shortCodes: ['CatiCall.call', 'CatiCall.active_call', 'CatiCall.next_call'],
      },
      editFeedback: {
        shortCodes: ['Feedback.update'],
        children: {
          canAssignFeedback: {
            shortCodes: ['Assignment.create'],
          },
          archiveFeedback: {
            shortCodes: ['Feedback.archive'],
          },
          markAsSpam: {
            shortCodes: ['Feedback.mark_as_spam'],
          },
          deleteFeedback: {
            shortCodes: ['Feedback.destroy'],
          },
        },
      },
    },
  },
  usersNav: {
    shortCodes: ['UsersNav.index'],
  },
  profile: {
    shortCodes: ['User.show_preferences', 'User.update_preferences'],
  },
  contacts: {
    shortCodes: ['Customer.index', 'Customer.show'],
    children: {
      exportCustomer: {
        shortCodes: ['Customer.export_as_csv'],
      },
      importCustomer: {
        shortCodes: ['Customer.import_from_csv'],
      },
      addCustomer: {
        shortCodes: ['Customer.create'],
      },
      updateCustomer: {
        shortCodes: ['Customer.update'],
      },
      customerEvents: {
        shortCodes: ['Customer.events'],
      },
    },
  },
  flow: {
    shortCodes: ['Flow.index', 'Flow.show'],
    children: {
      configureFlow: {
        shortCodes: ['Flow.update_or_create'],
      },
      exportFlowCsv: {
        shortCodes: ['Flow.export_as_csv'],
      },
      exportFlowJson: {
        shortCodes: ['Flow.export_as_json'],
      },
      makeCopy: {
        shortCodes: ['Flow.duplicate'],
      },
      deleteFlow: {
        shortCodes: ['Flow.destroy'],
      },
      approveFlow: {
        shortCodes: ['Flow.approve'],
      },
      archiveFlow: {
        shortCodes: ['Flow.archive'],
      },
      showFlowUsage: {
        shortCodes: ['Flow.show_usages_in_nodes'],
      },
      analysis: {
        shortCodes: ['Flow.flow_analysis'],
      },
    },
  },
  channels: {
    shortCodes: ['Channel.index', 'Channel.show'],
    children: {
      caticallMetrics: {
        shortCodes: ['CatiCall.metrics'],
      },
      caticallExport: {
        shortCodes: ['CatiCall.export'],
      },
    },
  },
  reports: {
    shortCodes: ['Dashboard.index', 'Dashboard.show'],
    children: {
      configureDashboard: {
        shortCodes: ['Dashboard.create', 'Dashboard.update', 'Dashboard.master'],
      },
      deleteDashboard: {
        shortCodes: ['Dashboard.destroy'],
      },
      printReport: {
        shortCodes: ['Dashboard.print'],
      },
      saveAsPdf: {
        shortCodes: ['Dashboard.export_as_pdf'],
      },
      shareByEmail: {
        shortCodes: ['Dashboard.share_by_email'],
      },
      copyReport: {
        shortCodes: ['Dashboard.copy', 'Dashboard.duplicate'],
      },
      exportAsPng: {
        shortCodes: ['Dashboard.export_as_png'],
      },
      configureWidget: {
        shortCodes: [
          'DashboardWidget.create',
          'DashboardWidget.update',
          'DashboardWidget.destroy',
          'DashboardWidget.duplicate',
        ],
      },
      analysis: {
        shortCodes: ['Dashboard.dashboard_analysis'],
      },
    },
  },
  settings: {
    shortCodes: ['Admin.show'],
    children: {
      users: {
        shortCodes: ['User.index', 'User.show_preferences', 'User.update_preferences'],
        children: {
          timelineFeedback: {
            shortCodes: ['Feedback.timeline'],
          },
        },
      },
      auditLogs: {
        shortCodes: ['AuditLog.index'],
      },
      contactPolicy: {
        shortCodes: ['ContactPolicy.index', 'ContactPolicy.create', 'ContactPolicy.update'],
      },
      tagGroups: {
        shortCodes: ['TagGroup.index', 'TagGroup.show', 'TagGroup.create', 'TagGroup.update', 'TagGroup.destroy'],
      },
      quickResponses: {
        shortCodes: [
          'QuickResponse.index',
          'QuickResponse.show',
          'QuickResponse.create',
          'QuickResponse.update',
          'QuickResponse.destroy',
        ],
      },
      reviewWidgets: {
        shortCodes: [
          'CustomerReviewWidget.index',
          'CustomerReviewWidget.show',
          'CustomerReviewWidget.create',
          'CustomerReviewWidget.update',
          'CustomerReviewWidget.destroy',
        ],
      },
      knowledgeBases: {
        shortCodes: [
          'KnowledgeBase.index',
          'KnowledgeBase.show',
          'KnowledgeBase.update_or_create',
          'KnowledgeBase.destroy',
        ],
      },
      escalations: {
        shortCodes: [
          'Escalation.index',
          'Escalation.show',
          'Escalation.create',
          'Escalation.update',
          'Escalation.destroy',
        ],
      },
      automatedJobs: {
        shortCodes: ['Trigger.index', 'Trigger.show', 'Trigger.create', 'Trigger.update', 'Trigger.destroy'],
      },
      scheduleJobs: {
        shortCodes: [
          'ScheduleJob.index',
          'ScheduleJob.show',
          'ScheduleJob.update_or_create',
          'ScheduleJob.destroy',
          'ScheduleJob.schemas',
        ],
      },
      emailAutomations: {
        shortCodes: [
          'EmailAutomation.index',
          'EmailAutomation.show',
          'EmailAutomation.create',
          'EmailAutomation.update',
          'EmailAutomation.destroy',
          'EmailAutomation.trigger',
        ],
      },
      journeyMaps: {
        shortCodes: [
          'JourneyMap.index',
          'JourneyMap.show',
          'JourneyMap.create',
          'JourneyMap.update',
          'JourneyMap.destroy',
          'JourneyMap.generate_link',
        ],
      },
      accountSettings: {
        shortCodes: ['AccountDetail.show', 'AccountDetail.update'],
        children: {
          smsProviders: {
            shortCodes: [
              'SmsProvider.index',
              'SmsProvider.show',
              'SmsProvider.create',
              'SmsProvider.update',
              'SmsProvider.destroy',
            ],
          },
          mailProviders: {
            shortCodes: [
              'MailProvider.index',
              'MailProvider.show',
              'MailProvider.create',
              'MailProvider.update',
              'MailProvider.destroy',
            ],
          },
          hrisProviders: {
            shortCodes: [
              'HrisProvider.index',
              'HrisProvider.show',
              'HrisProvider.create',
              'HrisProvider.update',
              'HrisProvider.destroy',
            ],
          },
          feedbackProviders: {
            shortCodes: [
              'FeedbackProvider.index',
              'FeedbackProvider.show',
              'FeedbackProvider.create',
              'FeedbackProvider.update',
              'FeedbackProvider.destroy',
            ],
          },
          ftpAccounts: {
            shortCodes: ['FtpAccount.show', 'FtpAccount.create', 'FtpAccount.destroy'],
          },
          remoteDbAccounts: {
            shortCodes: [
              'RemoteDbAccount.index',
              'RemoteDbAccount.show',
              'RemoteDbAccount.create',
              'RemoteDbAccount.update',
              'RemoteDbAccount.destroy',
            ],
          },
          samlSettings: {
            shortCodes: ['SamlDetail.show', 'SamlDetail.create', 'SamlDetail.update', 'SamlDetail.destroy'],
          },
          smtpSettings: {
            shortCodes: ['SmtpDetail.show', 'SmtpDetail.create', 'SmtpDetail.update', 'SmtpDetail.destroy'],
          },
        },
      },
      securitySettings: {
        shortCodes: [
          'AccountSecuritySettings.index',
          'AccountSecuritySettings.create',
          'AccountSecuritySettings.update',
        ],
      },
      reportTheme: {
        shortCodes: [
          'DashboardTheme.index',
          'DashboardTheme.show',
          'DashboardTheme.create',
          'DashboardTheme.update',
          'DashboardTheme.destroy',
        ],
      },
      hierarchy: {
        shortCodes: ['Hierarchy.index', 'Hierarchy.tree_view', 'Hierarchy.import'],
      },
      customerSchema: {
        shortCodes: [
          'CustomerSchema.index',
          'CustomerSchema.show',
          'CustomerSchema.create',
          'CustomerSchema.update',
          'CustomerSchema.destroy',
        ],
      },
    },
  },
  workflows: {
    shortCodes: ['Workflow.index', 'Workflow.show'],
    children: {
      workflowCreate: {
        shortCodes: ['Workflow.create'],
      },
      workflowStatusUpdate: {
        shortCodes: ['Workflow.status_update'],
      },
      workflowRunHistory: {
        shortCodes: ['Workflow.run_history'],
      },
      workflowDelete: {
        shortCodes: ['Workflow.delete'],
      },
      workflowEdit: {
        shortCodes: ['Workflow.edit'],
      },
      workflowPreview: {
        shortCodes: ['Workflow.preview'],
      },
    },
  },
  employees: {
    shortCodes: ['Employee.index', 'Employee.show'],
    children: {
      updateEmployee: {
        shortCodes: ['Employee.update'],
      },
      exportEmployee: {
        shortCodes: ['Employee.export_as_csv'],
      },
      importEmployee: {
        shortCodes: ['Employee.import_from_csv'],
      },
    },
  },
  exDashboard: {
    shortCodes: ['ExDashboard.index', 'ExDashboard.show'],
  },
  exReports: {
    shortCodes: ['ExReports.index', 'ExReports.show'],
  },
  catiCall: {
    shortCodes: ['CatiCall.active_call'],
  },
  catiList: {
    shortCodes: ['CatiCall.index'],
    children: {
      updateCatiCall: {
        shortCodes: ['CatiCall.update'],
      },
      deleteCatiCall: {
        shortCodes: ['CatiCall.destroy'],
      },
    },
  },
  advancedTextAnalysis: {
    shortCodes: ['AdvancedTextAnalysis.index'],
    children: {
      advancedTextAnalysisLog: {
        shortCodes: ['AdvancedTextAnalysisLog.index'],
      },
      aiModel: {
        shortCodes: ['AiModel.index'],
      },
      intelligentTags: {
        shortCodes: ['IntelligentTags.index'],
      },
      contentCategories: {
        shortCodes: ['ContentCategory.update'],
      },
    },
  },
};

export function transformPermissions(permissions: string[]) {
  function mapPermissions(permission: Permission): Permission {
    return Object.entries(permission).reduce((acc, [key, value]) => {
      const isActive = value.shortCodes.every((code) => permissions.includes(code));

      if (!isActive) {
        return {
          ...acc,
          [key]: {
            ...value,
            active: false,
          },
        };
      }

      return {
        ...acc,
        [key]: {
          active: true,
          shortCodes: value.shortCodes,
          children: value.children ? mapPermissions(value.children) : null,
        } as PermissionItem,
      };
    }, {} as Permission);
  }

  return mapPermissions(DEFAULT_PERMISSIONS);
}
