import { ComboboxItem } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { VSelect, VSelectProps } from '@vision/ui/components';
import { UseLazyLoadSelectOptions, useLazyLoadSelect } from '@vision/ui/hooks';
import { OptionsGroup } from '@vision/ui/interfaces';

interface VLazyLoadSelectProps<RequestType, ResponseType>
  extends Omit<VSelectProps, 'data'>,
    UseLazyLoadSelectOptions<RequestType, ResponseType> {
  onDataChange?: (data: ResponseType[]) => void;
  onSelectItemsChange?: (data: Array<ComboboxItem | OptionsGroup>) => void;
  searchKey?: string;
  resetChangeRequestParameters?: boolean;
}

export function VLazyLoadSelect<RequestType, ResponseType>({
  apiRequestParameters,
  disabledCondition,
  multiSelectItemMapper,
  onDataChange,
  onSelectItemsChange,
  searchable = true,
  singleSelectItemMapper,
  useLazyApiQueryFunction,
  searchKey,
  filterKey,
  resetChangeRequestParameters,
  onDefaultValuesLoaded,
  ...props
}: VLazyLoadSelectProps<RequestType, ResponseType>) {
  const {
    allData,
    allDataSelectItems,
    contextValue: { loadMore },
    dataSelectItemsWithDisabledCondition,
    isFilterQueryLoading,
    isLoading: isLoadingLazyLoadSelect,
    setSearchQuery,
  } = useLazyLoadSelect({
    filterKey,
    searchKey,
    apiRequestParameters,
    disabledCondition,
    multiSelectItemMapper,
    singleSelectItemMapper,
    useLazyApiQueryFunction,
    resetChangeRequestParameters,
    onDefaultValuesLoaded,
  });

  const handleLoadMore = () => {
    loadMore();
  };

  useDidUpdate(() => {
    onDataChange?.(allData);
  }, [allData]);

  useDidUpdate(() => {
    onSelectItemsChange?.(allDataSelectItems);
  }, [allDataSelectItems]);

  const isLoading = isFilterQueryLoading || isLoadingLazyLoadSelect;

  return (
    <VSelect
      data={dataSelectItemsWithDisabledCondition}
      loading={isLoading}
      onScrollToBottom={handleLoadMore}
      onSearchChange={setSearchQuery}
      searchable={searchable}
      {...props}
    />
  );
}
