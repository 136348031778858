import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import {
  ApiBaseRequest,
  ApiResponse,
  CreateTagGroupRequest,
  SimpleTagGroupItem,
  TagGroup,
} from '@vision/ui/interfaces';
import { buildEndpointWithQuery, insertIfObject } from '@vision/ui/utils';
import { axiosBaseQuery } from './base.service';

export const TagService = createApi({
  reducerPath: 'TagService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getTagGroups: builder.query<ApiResponse<TagGroup[]>, ApiBaseRequest>({
      query: ({ nodeId, query, sortBy, sortOrder }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/tag_groups`, query),
        method: HttpMethod.GET,
        params: {
          ...insertIfObject(!!sortBy, {
            sort_by: sortBy,
          }),
          ...insertIfObject(!!sortOrder, {
            sort_order: sortOrder,
          }),
        },
      }),
    }),
    fetchTagWithPagination: builder.query<
      ApiResponse<SimpleTagGroupItem[]>,
      ApiBaseRequest & { tagGroups?: Array<string> }
    >({
      query: ({ nodeId, query, tagGroups, sortBy, sortOrder }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/tag_groups/tags`, query),
        method: HttpMethod.GET,
        params: {
          ...insertIfObject(!!tagGroups && !!tagGroups.length, {
            tag_group_ids: tagGroups?.join(','),
          }),
          ...insertIfObject(!!sortBy, {
            sort_by: sortBy,
          }),
          ...insertIfObject(!!sortOrder, {
            sort_order: sortOrder,
          }),
        },
      }),
    }),
    createTagGroup: builder.mutation<ApiResponse<TagGroup>, { nodeId: string; data: CreateTagGroupRequest }>({
      query: ({ nodeId, data }) => ({
        url: `/v2/accounts/${nodeId}/tag_groups`,
        method: HttpMethod.POST,
        data,
      }),
    }),
    updateTagGroup: builder.mutation<ApiResponse<TagGroup>, { nodeId: string; data: TagGroup }>({
      query: ({ nodeId, data }) => ({
        url: `/v2/accounts/${nodeId}/tag_groups/${data.id}`,
        method: HttpMethod.POST,
        data,
      }),
    }),
    deleteTagGroup: builder.mutation<void, { nodeId: string; tagGroupId: string }>({
      query: ({ nodeId, tagGroupId }) => ({
        url: `/v2/accounts/${nodeId}/tag_groups/${tagGroupId}/delete`,
        method: HttpMethod.POST,
      }),
    }),
    getAllTagGroups: builder.query<TagGroup[], ApiBaseRequest>({
      query: ({ nodeId }) => ({
        url: `/v1/tag_groups?node_id=${nodeId}&include_internal=true`,
        method: HttpMethod.GET,
      }),
    }),
  }),
});

export const {
  useCreateTagGroupMutation,
  useDeleteTagGroupMutation,
  useGetAllTagGroupsQuery,
  useLazyGetTagGroupsQuery,
  useLazyFetchTagWithPaginationQuery,
  useUpdateTagGroupMutation,
} = TagService;
