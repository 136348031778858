import { ComboboxItem } from '@mantine/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ExcelCsvParseResultWithFileInfo,
  ImportedFileColumnMappings,
  KeyValueWithIndex,
  SchemaField,
  User,
} from '@vision/ui/interfaces';
import { AccountService, NodesService } from '@vision/ui/services';
import { string } from 'yup';

export interface ContactsStateType {
  importedFileColumnMappingSelections: ComboboxItem[];
  csvOrExcelResult: ExcelCsvParseResultWithFileInfo;
  importedFileColumnMapping: ImportedFileColumnMappings[];
  invalidEmailsFromImportedFile: KeyValueWithIndex[];
  schemaFields: SchemaField[];
  users: User[];
}

const initialState: ContactsStateType = {
  importedFileColumnMappingSelections: [],
  csvOrExcelResult: {
    rows: [],
    file: null,
    headers: [],
  },
  importedFileColumnMapping: [],
  invalidEmailsFromImportedFile: [],
  schemaFields: [],
  users: [],
};

function processCsvOrExcelColumnMappings(state: ContactsStateType) {
  const mappings: ImportedFileColumnMappings[] = state.csvOrExcelResult.headers.map((header) => ({
    label: header,
    items: state.schemaFields.map((field) => ({
      label: field.field_name,
      value: field.field_code,
    })),
  }));

  state.importedFileColumnMapping = mappings;
  state.importedFileColumnMappingSelections = mappings.map((item) => {
    const matchedItem = item.items.find(({ label }) => item.label === label);
    return {
      label: item.label,
      value: matchedItem ? matchedItem.value : null,
    };
  });
}

function processInvalidEmails(state: ContactsStateType): KeyValueWithIndex[] {
  return state.csvOrExcelResult.rows
    .reduce(
      (acc, list, rowIndex) => [
        ...acc,
        ...list.reduce(
          (subAcc, value, index) => [
            ...subAcc,
            {
              key: state.csvOrExcelResult.headers[index],
              value,
              rowIndex: rowIndex + 1,
            },
          ],
          [] as KeyValueWithIndex[],
        ),
      ],
      [] as KeyValueWithIndex[],
    )
    .filter((item) => {
      // Possible keys are email, e-mail etc...
      const key = item.key.replace('-', '').toLowerCase();
      if (key === 'email' && item.value) {
        return !string().email().isValidSync(item.value);
      }
      return false;
    });
}

export const ContactsState = createSlice({
  name: 'ContactsState',
  initialState,
  reducers: {
    updateCsvOrExcelParseResults(state, { payload }: PayloadAction<ExcelCsvParseResultWithFileInfo>) {
      state.csvOrExcelResult = payload;

      // Import edilmek istenen csv/excel dosyasında hatalı email olup olmadığı kontrol ediliyor
      state.invalidEmailsFromImportedFile = processInvalidEmails(state);

      if (!state.importedFileColumnMapping.length) {
        processCsvOrExcelColumnMappings(state);
      }
    },
    updateCsvColumnMappingSelections(state, { payload }: PayloadAction<{ item: ComboboxItem; index: number }>) {
      const { item, index } = payload;
      state.importedFileColumnMappingSelections[index] = item;
      state.importedFileColumnMapping.forEach((item) => {
        item.items.forEach((childItem) => {
          childItem.disabled = state.importedFileColumnMappingSelections.some(
            (selection) => selection.value === childItem.value,
          );
        });
      });
    },
    resetCsvImportFlow(state) {
      state.importedFileColumnMapping = initialState.importedFileColumnMapping;
      state.importedFileColumnMappingSelections = initialState.importedFileColumnMappingSelections;
      state.csvOrExcelResult = initialState.csvOrExcelResult;
      state.invalidEmailsFromImportedFile = initialState.invalidEmailsFromImportedFile;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(AccountService.endpoints.schemaFields.matchFulfilled, (state, { payload }) => {
      state.schemaFields = payload;

      processCsvOrExcelColumnMappings(state);
    });
    builder.addMatcher(NodesService.endpoints.nodeUsers.matchFulfilled, (state, { payload }) => {
      state.users = payload;
    });
  },
});

export const { updateCsvOrExcelParseResults, updateCsvColumnMappingSelections, resetCsvImportFlow } =
  ContactsState.actions;
