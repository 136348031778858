import { Flex, Loader, Text } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { SwitchCase } from '@vision/ui/components';
import { useDataGridContext } from '@vision/ui/context';
import { FilterType } from '@vision/ui/enums';
import { Filter, FilterOption, FilterSelections } from '@vision/ui/interfaces';
import { useDynamicFilterOptionsQuery } from '@vision/ui/services';
import { ensureArray, getValueByActiveLanguage, isObject } from '@vision/ui/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';
import { DataGridFilterCheckboxControl } from '../DataGridFilterCheckboxControl';
import { DataGridFilterDatepickerControl } from '../DataGridFilterDatepickerControl';
import { DataGridFilterRadioControl } from '../DataGridFilterRadioControl';
import {
  DataGridFilterRadioDatepickerControl,
  DataGridFilterRadioDatepickerControlProps,
} from '../DataGridFilterRadioDatepickerControl';
import { DataGridFilterTextControl } from '../DataGridFilterTextControl';

interface DataGridFilterRendererProps {
  isDefaultSelectedFilter?: boolean;
  filter: Filter;
  filterSelections: FilterSelections[];
  onFilterChange: (selections: FilterSelections[]) => void;
  searchText?: string;
  radioDatepickerControlOptions?: Pick<DataGridFilterRadioDatepickerControlProps, 'datepickerVisible'>;
  size?: 'xs' | 'sm';
}

function _DataGridFilterRenderer({
  isDefaultSelectedFilter,
  filter,
  filterSelections,
  onFilterChange,
  searchText,
  radioDatepickerControlOptions,
  size = 'sm',
}: DataGridFilterRendererProps) {
  const { i18n, t } = useTranslation(['translation', 'data-grid']);
  const {
    data: dynamicOptionsResponse,
    isFetching,
    isError,
  } = useDynamicFilterOptionsQuery(filter.options_url, {
    skip: !filter.dynamic,
  });
  const [temporaryFilterSelections, setTemporaryFilterSelections] = useState(() => [...filterSelections]);
  const [filterState, setFilterState] = useState(() => filter);
  const [optionsFetched, setOptionsFetched] = useState(false);
  const { updateFilter } = useDataGridContext();

  useEffect(() => {
    if (dynamicOptionsResponse && dynamicOptionsResponse.data?.length > 0) {
      if (optionsFetched) {
        return;
      }

      const mapped = ensureArray(dynamicOptionsResponse.data).map(
        (item) =>
          ({
            label: isObject(item.label) ? getValueByActiveLanguage(item.label as Record<string, string>) : item.label,
            value: item.value,
          }) as FilterOption,
      );
      const updatedFilter: Filter = {
        ...filterState,
        options: mapped,
      };
      setFilterState(updatedFilter);
      updateFilter(updatedFilter);
      setOptionsFetched(true);
    }
  }, [filterState, dynamicOptionsResponse, optionsFetched]);

  useDeepCompareEffect(() => {
    setTemporaryFilterSelections([...filterSelections]);
  }, [filterSelections]);

  useDeepCompareEffect(() => {
    setFilterState(filter);
  }, [filter]);

  useDidUpdate(() => {
    setOptionsFetched(false);
  }, [i18n.language]);

  if (filter.dynamic && isFetching) {
    return (
      <Flex justify="center">
        <Loader />
      </Flex>
    );
  }

  if (
    filter.dynamic &&
    !isFetching &&
    (isError ||
      !dynamicOptionsResponse ||
      !dynamicOptionsResponse?.data?.length ||
      !ensureArray(filterState?.options).length)
  ) {
    // Eğer option'lar dinamik olarak api'den alınıyorsa ve api isteği tamamlandıktan sonra hata varsa veya options gelmediyse gösterilir.
    // Bu şarta düşülmesi beklenmiyor ama ne olur ne olmaz.
    return (
      <Flex justify="center">
        <Text c="gray" data-testid="text-data-grid-filter-renderer-empty">
          {t(isError ? 'data-grid:filterOptionFetchError' : 'data-grid:filterOptionEmpty')}
        </Text>
      </Flex>
    );
  }

  return (
    <SwitchCase
      value={filter.type}
      caseBy={{
        [FilterType.CHECKBOX]: (
          <DataGridFilterCheckboxControl
            filter={filterState}
            filterSelections={temporaryFilterSelections}
            onChange={onFilterChange}
            searchText={searchText}
            size={size}
          />
        ),
        [FilterType.RADIO]: (
          <DataGridFilterRadioControl
            clearable={!isDefaultSelectedFilter}
            filter={filterState}
            filterSelections={temporaryFilterSelections}
            onChange={onFilterChange}
            searchText={searchText}
            size={size}
          />
        ),
        [FilterType.RADIO_DATEPICKER]: (
          <DataGridFilterRadioDatepickerControl
            filter={filterState}
            filterSelections={temporaryFilterSelections}
            onChange={onFilterChange}
            searchText={searchText}
            size={size}
            {...radioDatepickerControlOptions}
          />
        ),
        [FilterType.DATEPICKER]: (
          <DataGridFilterDatepickerControl
            filter={filterState}
            filterSelections={temporaryFilterSelections}
            onChange={onFilterChange}
          />
        ),
        [FilterType.TEXT]: (
          <DataGridFilterTextControl
            filter={filterState}
            filterSelections={temporaryFilterSelections}
            onChange={onFilterChange}
            size={size}
          />
        ),
      }}
    />
  );
}

export const DataGridFilterRenderer = React.memo(_DataGridFilterRenderer);
