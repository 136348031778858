import { If, VLazyLoadMultiSelect, VSelectOptionWithDescription } from '@vision/ui/components';
import { useEmojiContext, useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues, SimpleFlowQuestion } from '@vision/ui/interfaces';
import {
  useLazyFetchFlowQuestionsWithPaginationQuery,
  useLazyFetchFlowsWithPaginationQuery,
} from '@vision/ui/services';
import { getBodyText, isObjectNotEmpty, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareQuestionTitleProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareQuestionTitle({
  compareItemType,
  required,
}: ReportsDetailGraphicCompareQuestionTitleProps) {
  const { t, i18n } = useTranslation();
  const { textWithNativeEmoji } = useEmojiContext();
  const accountId = useSelectedAccountId();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();

  const handleDefaultValuesLoaded = (data: SimpleFlowQuestion[]) => {
    const newIds = formik.values.selectedCompareCriteria.selectedQuestionIds.filter((id) =>
      data.some((item) => item.id === id),
    );
    formik.setFieldValue('selectedCompareCriteria.selectedQuestionIds', newIds);
  };

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VLazyLoadMultiSelect
        id="report-compare-question-title-flow"
        data-testid="input-report-compare-question-title-flow"
        label={t('flows')}
        useLazyApiQueryFunction={useLazyFetchFlowsWithPaginationQuery}
        apiRequestParameters={{ nodeId: accountId, sortBy: 'name', sortOrder: 'asc' }}
        multiSelectItemMapper={(items) => {
          return items.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        }}
        searchKey="flow_name"
        value={formik.values.selectedCompareCriteria.selectedQuestionFlowIds}
        searchable={true}
        clearable={true}
        onBlur={formik.handleBlur}
        onChange={(values) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionFlowIds', values)}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionFlowIds')}
        withAsterisk={required}
        itemComponent={VSelectOptionWithDescription}
      />

      <If value={formik.values.selectedCompareCriteria.selectedQuestionFlowIds.length > 0}>
        <VLazyLoadMultiSelect
          id="report-compare-question-title-question"
          data-testid="input-report-compare-question-title-question"
          label={t('question')}
          useLazyApiQueryFunction={useLazyFetchFlowQuestionsWithPaginationQuery}
          apiRequestParameters={{
            nodeId: accountId,
            flowIds: formik.values.selectedCompareCriteria.selectedQuestionFlowIds,
            sortBy: 'name',
            sortOrder: 'asc',
          }}
          multiSelectItemMapper={(items) => {
            return items
              .filter((item) => isObjectNotEmpty(item.body))
              .map((item) => ({
                value: item.id,
                label: textWithNativeEmoji(getBodyText(item.body, i18n.language)),
                description: item?.flow_name,
              }));
          }}
          searchKey="question_body"
          searchable={true}
          value={formik.values.selectedCompareCriteria.selectedQuestionIds}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionIds', e)}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionIds')}
          resetChangeRequestParameters={true}
          withAsterisk={required}
          onDefaultValuesLoaded={handleDefaultValuesLoaded}
          itemComponent={VSelectOptionWithDescription}
        />
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
