import { Drawer, DrawerBodyProps, DrawerProps, Group, GroupProps, Tooltip } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { DrawerConfig } from '@vision/theme';
import { Breakpoints } from '@vision/ui/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './VDrawer.module.scss';

interface VDrawerProps extends DrawerProps {
  children: React.ReactNode;
}

interface VDrawerBodyProps extends DrawerBodyProps {}

interface VDrawerFooterProps {
  bottomContent?: React.ReactNode;
  justify?: GroupProps['justify'];
  topContent?: React.ReactNode;
}

function VDrawerBody({ children, ...props }: React.PropsWithChildren<VDrawerBodyProps>) {
  return <Drawer.Body {...props}>{children}</Drawer.Body>;
}

function VDrawerFooter({
  children,
  bottomContent,
  justify = 'right',
  topContent,
}: React.PropsWithChildren<VDrawerFooterProps>) {
  return (
    <div className={classes.footer}>
      {topContent}
      <Group justify={justify} gap={10} className={classes.content}>
        {children}
      </Group>
      {bottomContent}
    </div>
  );
}

export function VDrawer({ size, title, children, ...props }: VDrawerProps) {
  const { t } = useTranslation();
  const matches = useMediaQuery(Breakpoints.DOWN.SM);
  const defaultSize = DrawerConfig.defaultProps.size;
  const calculatedSize = matches ? '100%' : size || defaultSize;

  return (
    <Drawer.Root
      position={DrawerConfig.defaultProps.position}
      className={classes.vDrawer}
      {...props}
      size={calculatedSize}
    >
      <Drawer.Overlay {...DrawerConfig.defaultProps.overlayProps} />
      <Drawer.Content>
        <Drawer.Header>
          <Drawer.Title>{title}</Drawer.Title>
          <Tooltip label={t('close')}>
            <Drawer.CloseButton />
          </Tooltip>
        </Drawer.Header>
        {children}
      </Drawer.Content>
    </Drawer.Root>
  );
}

VDrawer.Body = VDrawerBody;
VDrawer.Footer = VDrawerFooter;
