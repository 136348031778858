import { Box, BoxProps } from '@mantine/core';

interface ActionPanelBodyProps extends BoxProps {}

export function ActionPanelBody({ children, ...props }: React.PropsWithChildren<ActionPanelBodyProps>) {
  return (
    <Box p={20} {...props}>
      {children}
    </Box>
  );
}
