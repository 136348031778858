import { TreeNodeData } from '@mantine/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContentCategory } from '@vision/ui/interfaces';
import { ContentCategoriesService } from '@vision/ui/services';

/*
type RecursiveConvertContentCategoryTreeToTreeNodeData = (ContentCategory & {
  children?: ReType<ContentCategory, 'children'>[];
} & {
  children: ContentCategory[];
})[];

function recursiveConvertContentCategoryTreeToTreeNodeData(
  tree: RecursiveConvertContentCategoryTreeToTreeNodeData,
): TreeNodeData[] {
  return tree.map((item) => ({
    label: getValueByActiveLanguage(item.name),
    value: item.id,
    nodeProps: {
      parent: item.parent_id,
    },
    children: recursiveConvertContentCategoryTreeToTreeNodeData(
      item.children as RecursiveConvertContentCategoryTreeToTreeNodeData,
    ),
  }));
}
*/

/*function createContentCategoryTree(list: ContentCategory[]): TreeNodeData[] {
  const tree = arrayToTree(list, {
    parentId: 'parent_id',
    children: 'children',
    id: 'id',
  });

  return [
    {
      label: i18n.t('contentCategories'),
      value: 'root',
      children: recursiveConvertContentCategoryTreeToTreeNodeData(tree),
      nodeProps: {
        checkable: false,
      },
    },
  ];
}
*/

/*function getContentCategoriesStatusActive(list: ContentCategory[]): string[] {
  return list.filter((item) => item.status === 'active').map((item) => item.id);
}*/

export interface ContentCategoriesStateType {
  contentCategories: ContentCategory[];
  contentCategoriesTree: TreeNodeData[];
  contentCategoriesTreeCheckedState: string[];
}

const initialState: ContentCategoriesStateType = {
  contentCategories: [],
  contentCategoriesTree: [],
  contentCategoriesTreeCheckedState: [],
};

export const ContentCategoriesState = createSlice({
  name: 'ContentCategoriesState',
  initialState,
  reducers: {
    setContentCategoriesTree: (state) => {
      console.log('state.contentCategories', state.contentCategories);
      //state.contentCategoriesTree = createContentCategoryTree(state.contentCategories);
      //state.contentCategoriesTreeCheckedState = getContentCategoriesStatusActive(state.contentCategories);
    },
    setContentCategoriesTreeCheckedState: (state, action: PayloadAction<string[]>) => {
      state.contentCategoriesTreeCheckedState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(ContentCategoriesService.endpoints.getContentCategories.matchFulfilled, (state, { payload }) => {
      state.contentCategories = payload;
    });

    // If there is a performance issue here, we can improve the lookup time by using a Map or Object.
    builder.addMatcher(
      ContentCategoriesService.endpoints.updateContentCategory.matchFulfilled,
      (state, { payload }) => {
        const index = state.contentCategories.findIndex((item) => item.id === payload.id);
        if (index !== -1) {
          state.contentCategories[index] = payload;
        }
      },
    );
  },
});

export const { setContentCategoriesTree, setContentCategoriesTreeCheckedState } = ContentCategoriesState.actions;
