import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mantine/core';
import clsx from 'clsx';
import React from 'react';
import classes from './TooltipInfo.module.scss';

interface TooltipInfoProps {
  className?: string;
}

export function TooltipInfo({ children, className }: React.PropsWithChildren<TooltipInfoProps>) {
  return (
    <Tooltip label={children} maw={215}>
      <FontAwesomeIcon icon={faCircleInfo} className={clsx(classes.tooltipInfoIcon, className)} />
    </Tooltip>
  );
}
