import i18n from '@vision/ui/i18n';
import { KeyValue } from '../interfaces';

/**
 * Simple object check.
 */
export function isObject(item: any): boolean {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two or more objects.
 */
export function mergeDeep<T>(target: T, ...sources: T[]): T {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

/**
 * Get deeply nested value from an object
 *
 *```
 * const value = {
 *   a: {
 *     b: {
 *       c: 1
 *     }
 *   }
 * }
 *
 * getDeep(value, 'a.b.c') // 1
 * ```
 */
export function getDeep<T, R>(target: T, selector: string): R {
  return selector
    .replace(/\[([^[\]]*)]/g, '.$1.')
    .split('.')
    .filter((t) => t !== '')
    .reduce((prev: any, cur: string) => prev && prev[cur], target);
}

/**
 * @param obj object for omit
 * @param keys array of string for which keys are omitted from obj parameter
 */
export const omit = <K extends keyof T, T>(obj: T, keys: K | Array<K>): Omit<T, K> => {
  const keysArray = Array.isArray(keys) ? keys : [keys];

  return Object.entries(obj).reduce(
    (acc, [key, val]) => (!keysArray.some((_key) => _key === key) ? { ...acc, [key]: val } : acc),
    {},
  ) as Omit<T, K>;
};

/*
 * const a = [
 *   ...insertIfArray(condition, {
 *     key: 'value'
 *   })
 * ]
 *
 * const a = [
 *   ...insertIfArray(condition, [
 *   {
 *     key: 'value'
 *   }
 * ])
 * ]
 * */
export function insertIfArray<T>(condition: boolean, elements: T[] | T): T[] {
  if (!condition) {
    return [];
  }

  return Array.isArray(elements) ? elements : [elements];
}

/*
 * const a = {
 *   ...insertIfObject(condition, {
 *     key: 'value'
 *   })
 * }
 * */
export function insertIfObject<T>(condition: boolean, element: T): T {
  return condition ? element : ({} as T);
}

/*
 * Finds `obj[path][to][key]` from `path.to.key`
 * */
export function findObjectFromPath(obj: any, key: string) {
  return key.split('.').reduce((prev, curr) => prev?.[curr], obj);
}

export function conditionalFn<T, R>(compare: T, value: R): T {
  return typeof compare === 'function' ? compare(value) : compare;
}

export function convertArrayObjectToKeyValue(extraParams: KeyValue[]): Record<string, any> {
  return extraParams
    .filter((item) => !!item.key && !!item.value)
    .reduce((acc, item) => {
      return { ...acc, [item.key]: item.value };
    }, []);
}

export function convertObjectToKeyValueArray(inputObject: Record<string, any>): KeyValue[] {
  return Object.entries(inputObject).map(([key, value]) => ({ key, value }));
}

export const getValueByActiveLanguage = (record: Record<string, string>): string => {
  if (!record) {
    return '';
  }

  const language = i18n.language.toUpperCase();
  return record[language] || record['EN'] || Object.values(record)[0] || '';
};

// If an incoming object is not null, undefined and {}, it returns true
// We decided that it would not return from the API, but it keeps coming.
export const isObjectNotEmpty = (obj: unknown): boolean => {
  return obj !== null && obj !== undefined && typeof obj === 'object' && Object.keys(obj).length > 0;
};
