import { ComboboxItem } from '@mantine/core';
import i18n, { DEFAULT_LANG } from '@vision/ui/i18n';
import dayjs from 'dayjs';
import { DateFormatVariant } from '../interfaces';

function getParsedDate(date: number | string | Date) {
  if (date instanceof Date) {
    return date;
  }

  return new Date(date);
}

function getDateIntlLanguage(variant: DateFormatVariant, locale?: string) {
  // Date format lang should always be relative to "en" if variant is "full"
  if (variant === 'full') {
    return DEFAULT_LANG;
  }

  return locale || i18n.language;
}

function getTimeIntlLanguage(locale: string) {
  return locale || i18n.language;
}

function createDateIntlInstance(variant: DateFormatVariant, locale?: string) {
  const lang = getDateIntlLanguage(variant, locale);

  const options: Intl.DateTimeFormatOptions = {
    month: variant === 'medium' || variant === 'monthYear' ? 'long' : '2-digit',
    day: '2-digit',
  };

  if (variant === 'full' || variant === 'monthYear' || variant === 'year') {
    options.year = 'numeric';
  }

  return new Intl.DateTimeFormat(lang, options);
}

function createTimeIntlInstance(locale: string) {
  const lang = getTimeIntlLanguage(locale);

  return new Intl.DateTimeFormat(lang, {
    hour: 'numeric',
    minute: 'numeric',
  });
}

function formatDateByMonthYearVariant(date: number | string | Date, locale?: string) {
  const dateIntl = createDateIntlInstance('monthYear', locale);
  const timeIntl = createTimeIntlInstance(locale);
  const parsedDate = getParsedDate(date);

  const { month, year } = dateIntl
    .formatToParts(parsedDate)
    .reduce((acc, part) => ({ ...acc, [part.type]: part.value }), {} as Record<Intl.DateTimeFormatPartTypes, string>);

  return {
    date: `${month} ${year}`,
    time: timeIntl.format(parsedDate),
  };
}

function formatDateByYearVariant(date: number | string | Date, locale?: string) {
  const dateIntl = createDateIntlInstance('year', locale);
  const timeIntl = createTimeIntlInstance(locale);
  const parsedDate = getParsedDate(date);

  const { year } = dateIntl
    .formatToParts(parsedDate)
    .reduce((acc, part) => ({ ...acc, [part.type]: part.value }), {} as Record<Intl.DateTimeFormatPartTypes, string>);

  return {
    date: year,
    time: timeIntl.format(parsedDate),
  };
}

function formatDateByQuarterVariant(date: number | string | Date, locale?: string) {
  const dateIntl = createDateIntlInstance('year', locale);
  const timeIntl = createTimeIntlInstance(locale);
  const parsedDate = getParsedDate(date);

  const { year } = dateIntl
    .formatToParts(parsedDate)
    .reduce((acc, part) => ({ ...acc, [part.type]: part.value }), {} as Record<Intl.DateTimeFormatPartTypes, string>);

  const quarter = Math.ceil((parsedDate.getMonth() + 1) / 3);

  return {
    date: `${i18n.t('translation:shortQuarter')}${quarter} ${year}`,
    time: timeIntl.format(parsedDate),
  };
}

function formatDateByMediumVariant(date: number | string | Date, locale?: string) {
  const dateIntl = createDateIntlInstance('medium', locale);
  const timeIntl = createTimeIntlInstance(locale);
  const parsedDate = getParsedDate(date);

  return {
    date: dateIntl.format(parsedDate),
    time: timeIntl.format(parsedDate),
  };
}

export function getTimeListByInterval(interval = 60) {
  const timeList: string[] = [];

  let startTime = dayjs().startOf('day');
  const endTime = dayjs().endOf('day');

  while (startTime.isBefore(endTime)) {
    timeList.push(startTime.format('HH:mm'));
    startTime = startTime.add(interval, 'minute');
  }

  return timeList;
}

function formatDateByFullVariant(date: number | string | Date, locale?: string) {
  const dateIntl = createDateIntlInstance('full', locale);
  const timeIntl = createTimeIntlInstance(locale);
  const parsedDate = getParsedDate(date);
  const { day, month, year } = dateIntl
    .formatToParts(parsedDate)
    .reduce((acc, part) => ({ ...acc, [part.type]: part.value }), {} as Record<Intl.DateTimeFormatPartTypes, string>);

  return {
    date: `${day}/${month}/${year}`,
    time: timeIntl.format(parsedDate),
  };
}

export function formatDate(date: number | string | Date, variant: DateFormatVariant = 'full', locale = DEFAULT_LANG) {
  if (!date) {
    return;
  }

  if (variant === 'medium') {
    return formatDateByMediumVariant(date, locale);
  } else if (variant === 'monthYear') {
    return formatDateByMonthYearVariant(date, locale);
  } else if (variant === 'year') {
    return formatDateByYearVariant(date, locale);
  } else if (variant === 'quarter') {
    return formatDateByQuarterVariant(date, locale);
  }

  return formatDateByFullVariant(date, locale);
}

export function formatDateWithTemplate(date: number | string | Date, template: string) {
  return dayjs(date).format(template);
}

export function getThirtyDaysAgo(): Date {
  return dayjs().subtract(30, 'day').toDate();
}

export function createHourOptions(): ComboboxItem[] {
  const hours = Array.from(Array(24).keys());
  return hours.map((hour) => ({
    label: `${hour.toString().padStart(2, '0')}:00`,
    value: hour.toString(),
  }));
}

export function generateHourOptions(): ComboboxItem[] {
  const hours = Array.from(Array(24).keys());
  return hours.map((hour) => ({
    label: `${hour.toString().padStart(2, '0')}:00`,
    value: hour.toString(),
  }));
}

export function generateWeekdayOptions(): ComboboxItem[] {
  return dayjs
    .localeData()
    .weekdays()
    .map((day, index) => ({
      value: index.toString(),
      label: day,
    }));
}

export function generateDayOptions(): ComboboxItem[] {
  return Array.from({ length: 31 }, (_, day) => {
    const dayValue = (day + 1).toString();
    return {
      value: dayValue,
      label: dayValue,
    };
  });
}
