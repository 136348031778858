export function isMobile(): boolean {
  const ua = navigator.userAgent;
  return /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(ua);
}

export function isSafari(): boolean {
  const ua = navigator.userAgent;
  return /^((?!chrome|android).)*safari/i.test(ua);
}

export function isDesktopSafari(): boolean {
  return isSafari() && !isMobile();
}

function findScrollableParent(element: Element | null, doc: Document): Element | null {
  if (!element) return doc.documentElement;

  let currentElement: Element | null = element;

  while (currentElement && currentElement !== doc.documentElement) {
    // Check if element is scrollable
    const style = window.getComputedStyle(currentElement);
    const { overflowY, overflowX } = style;

    if (
      (overflowY === 'auto' || overflowY === 'scroll' || overflowX === 'auto' || overflowX === 'scroll') &&
      (currentElement.scrollHeight > currentElement.clientHeight ||
        currentElement.scrollWidth > currentElement.clientWidth)
    ) {
      return currentElement;
    }

    currentElement = currentElement.parentElement;
  }

  return doc.documentElement; // Fall back to document for scrolling
}

export function scrollElementBeneathIframe(event: WheelEvent | Event, targetElementContainer: Document): void {
  if (event.type === 'wheel') {
    event.preventDefault();
  }

  const wheelEvent = event as WheelEvent;

  // Get the target element beneath based on current mouse position
  const clientX = wheelEvent.clientX || window.scrollX;
  const clientY = wheelEvent.clientY || window.scrollY;

  // Find scrollable parent element beneath the pointer
  const elementBeneath = findScrollableParent(
    targetElementContainer.elementFromPoint(clientX, clientY),
    targetElementContainer,
  );

  if (elementBeneath) {
    const scrollMultiplier = 1; // Adjust scroll speed

    if (elementBeneath === targetElementContainer.documentElement || elementBeneath === targetElementContainer.body) {
      window.parent.scrollBy({
        top: wheelEvent.deltaY * scrollMultiplier,
        left: wheelEvent.deltaX * scrollMultiplier,
        behavior: 'smooth',
      });
    } else {
      elementBeneath.scrollTop += wheelEvent.deltaY * scrollMultiplier;
      elementBeneath.scrollLeft += wheelEvent.deltaX * scrollMultiplier;
    }
  }
}
