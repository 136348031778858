import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { ApiResponse, HttpBaseRequest, SmsProvider, SmsProviderType } from '../interfaces';
import { buildEndpointWithQuery } from '../utils';
import { AccountService } from './accounts.service';
import { axiosBaseQuery } from './base.service';

export const SmsProviderService = createApi({
  reducerPath: 'SmsProviderService',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['GetSmsProviders', 'GetSmsProviderDetail'],
  endpoints: (builder) => ({
    getSmsProviders: builder.query<ApiResponse<SmsProvider[]>, HttpBaseRequest<{ nodeId: string; query: string }>>({
      query: ({ nodeId, query }) => ({
        url: buildEndpointWithQuery(`/v2/accounts/${nodeId}/settings/sms_provider_interfaces`, query),
        method: HttpMethod.GET,
      }),
      providesTags: ['GetSmsProviders'],
    }),
    getSmsProviderDetail: builder.query<
      ApiResponse<SmsProviderType>,
      HttpBaseRequest<{ nodeId: string; providerType: string; providerId: string }>
    >({
      query: ({ nodeId, providerType, providerId }) => ({
        url: `/v2/accounts/${nodeId}/${providerType}s/${providerId}`,
        method: HttpMethod.GET,
      }),
      providesTags: ['GetSmsProviderDetail'],
    }),
    createSmsProvider: builder.mutation<
      { id: string },
      HttpBaseRequest<{ nodeId: string; providerType: string; data: SmsProviderType }>
    >({
      query: ({ providerType, nodeId, data }) => ({
        url: `/v2/accounts/${nodeId}/${providerType}`,
        method: HttpMethod.POST,
        data: data,
      }),
      invalidatesTags: ['GetSmsProviders'],
      async onQueryStarted(result, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(AccountService.util.invalidateTags(['GetAccountSettings']));
      },
    }),
    updateSmsProvider: builder.mutation<
      SmsProviderType,
      HttpBaseRequest<{ nodeId: string; providerType: string; providerId: string; data: SmsProviderType }>
    >({
      query: ({ nodeId, providerType, data, providerId }) => ({
        url: `/v2/accounts/${nodeId}/${providerType}/${providerId}`,
        method: HttpMethod.POST,
        data: data,
      }),
      invalidatesTags: ['GetSmsProviders'],
    }),
    deleteSmsProvider: builder.mutation<void, HttpBaseRequest<{ providerId: string; nodeId: string }>>({
      query: ({ providerId, nodeId }) => ({
        url: `/v2/accounts/${nodeId}/sms_provider_interfaces/${providerId}/delete`,
        method: HttpMethod.POST,
      }),
      invalidatesTags: ['GetSmsProviders'],
    }),
  }),
});

export const {
  useCreateSmsProviderMutation,
  useLazyGetSmsProvidersQuery,
  useDeleteSmsProviderMutation,
  useLazyGetSmsProviderDetailQuery,
  useUpdateSmsProviderMutation,
} = SmsProviderService;
