import { Stack } from '@mantine/core';
import { VLazyLoadMultiSelect, VSelect, VSelectOptionWithDescription } from '@vision/ui/components';
import { ChannelType } from '@vision/ui/enums';
import { useChannelTypes, useSelectedAccountId } from '@vision/ui/hooks';
import { ReportsDetailGraphicSchemaValues, SimpleChannel } from '@vision/ui/interfaces';
import { useLazyFetchChannelsWithPaginationQuery } from '@vision/ui/services';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../../../ReportsDetailGraphicCompareComponents';

const FunnelChannelTypes = [
  ChannelType.LINK,
  ChannelType.EMAIL,
  ChannelType.SMS,
  ChannelType.PUSH_NOTIFICATION,
  ChannelType.WHATSAPP,
  ChannelType.WEB_WIDGET,
];

// It is mentioned as campaignType in v1, so it is used here in the same way.
// Only the name has been changed.
// channelType and channel have been removed from the filter.
// campaignType has been used instead of channelType.
// channel continues to be used in the same way

export function FunnelChart1ValueComponent() {
  const { t } = useTranslation(['translation', 'page-reports']);
  const accountId = useSelectedAccountId();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const channelTypeSelectItems = useChannelTypes(FunnelChannelTypes);

  const handleChannelTypeChange = (value: string) => {
    if (!value) {
      formik.setValues({
        ...formik.values,
        selectedFilters: {
          ...formik.values.selectedFilters,
          selectedChannelIds: [],
        },
        selectedCustomize: {
          ...formik.values.selectedCustomize,
          campaignType: null,
        },
      });
      return;
    }

    formik.setFieldValue('selectedCustomize.campaignType', value as ChannelType);
  };

  const handleDefaultValuesLoaded = (items: SimpleChannel[]) => {
    const newIds = formik.values.selectedFilters.selectedChannelIds.filter((id) =>
      items.some((item) => item.id === id),
    );
    formik.setFieldValue('selectedFilters.selectedChannelIds', newIds);
  };

  return (
    <Stack gap={10} mt={-20}>
      <ReportsDetailGraphicCompareLayout title={t('page-reports:reportCompareGroupItemType.channel-name')}>
        <Stack gap={10}>
          <VSelect
            data-testid="input-funnel-channel"
            id="input-funnel-channel"
            label={t('channelType')}
            data={channelTypeSelectItems}
            value={formik.values.selectedCustomize.campaignType}
            onChange={handleChannelTypeChange}
            withAsterisk={true}
            clearable={true}
          />
          <VLazyLoadMultiSelect
            id="report-filter-channels"
            data-testid="input-report-filter-channels"
            label={t('channels')}
            useLazyApiQueryFunction={useLazyFetchChannelsWithPaginationQuery}
            apiRequestParameters={{
              nodeId: accountId,
              types: [formik.values.selectedCustomize.campaignType as ChannelType],
              sortBy: 'name',
              sortOrder: 'asc',
            }}
            multiSelectItemMapper={(items) => {
              return items.map((item) => ({
                value: item.id,
                label: item.name,
                description: item?.type,
              }));
            }}
            searchKey="node_name"
            onDefaultValuesLoaded={handleDefaultValuesLoaded}
            disabled={!formik.values.selectedCustomize.campaignType}
            value={formik.values.selectedFilters.selectedChannelIds}
            withAsterisk={true}
            onChange={(value) => formik.setFieldValue('selectedFilters.selectedChannelIds', value)}
            resetChangeRequestParameters={true}
            clearable={true}
            itemComponent={VSelectOptionWithDescription}
          />
        </Stack>
      </ReportsDetailGraphicCompareLayout>
    </Stack>
  );
}
