import { TreeSelect } from '@vision/ui/components';
import { Status } from '@vision/ui/enums';
import { useSelectedAccountId } from '@vision/ui/hooks';
import { ContentCategory, ReportsDetailGraphicSchemaValues, TreeSelectItemType } from '@vision/ui/interfaces';
import { useGetContentCategoriesQuery } from '@vision/ui/services';
import { translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getReportDataName } from '../../../utils/chart.utils';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

interface ReportsDetailGraphicCompareContentCategoryProps extends ReportsDetailGraphicBaseProps {}

function itemMapper(item: ContentCategory): TreeSelectItemType {
  return {
    children: [],
    disabled: false,
    id: item.id,
    label: getReportDataName(item.name),
    parentId: item.parent_id,
  };
}

export function ReportsDetailGraphicCompareContentCategory({
  required,
}: ReportsDetailGraphicCompareContentCategoryProps) {
  const { t } = useTranslation();
  const accountId = useSelectedAccountId();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const [value, setValue] = useState<string[]>(formik.values.selectedCompareCriteria.selectedContentCategoryIds || []);

  const { data: contentCategories, isFetching } = useGetContentCategoriesQuery({
    accountId,
    status: Status.ACTIVE,
  });

  const handleChange = (val: string[]) => {
    setValue(val);
    formik.setFieldValue('selectedCompareCriteria.selectedContentCategoryIds', val);
  };

  return (
    <ReportsDetailGraphicCompareLayout title={t('contentCategories')}>
      <TreeSelect
        items={contentCategories ?? []}
        mapper={itemMapper}
        value={value}
        onChange={handleChange}
        withDropdown={true}
        inputProps={{
          label: t('contentCategories'),
          error: translateErrorMessage(formik, 'selectedCompareCriteria.selectedContentCategoryIds'),
          withAsterisk: required,
          placeholder: t('selectContentCategories'),
        }}
        loading={isFetching}
        showSelectedItemsOnTop={false}
        withSubCategories={true}
      />
    </ReportsDetailGraphicCompareLayout>
  );
}
