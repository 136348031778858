import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ExcelCsvParseResultWithFileInfo } from '@vision/ui/interfaces';

export interface IntelligentTagsStateType {
  tagIntelligentTagsDrawerOpened: boolean;
  selectedTagIntelligentTagId: string;
  tagTrainingImportModalOpened: boolean;
  tagTrainingImportCsvOrExcelResult: ExcelCsvParseResultWithFileInfo;
}

const initialState: IntelligentTagsStateType = {
  tagIntelligentTagsDrawerOpened: false,
  selectedTagIntelligentTagId: null,
  tagTrainingImportModalOpened: false,
  tagTrainingImportCsvOrExcelResult: {
    rows: [],
    file: null,
    headers: [],
  },
};

export const IntelligentTagsState = createSlice({
  name: 'IntelligentTagsState',
  initialState,
  reducers: {
    closeTagIntelligentTagsDrawerAction: (state) => {
      state.tagIntelligentTagsDrawerOpened = false;
      state.selectedTagIntelligentTagId = null;
    },
    openTagIntelligentTagsDrawerAction: (state) => {
      state.tagIntelligentTagsDrawerOpened = true;
    },
    editTagIntelligentTagsDrawerAction: (state, { payload }: PayloadAction<string>) => {
      state.tagIntelligentTagsDrawerOpened = true;
      state.selectedTagIntelligentTagId = payload;
    },
    setTagTrainingImportModalAction: (state, { payload }: PayloadAction<boolean>) => {
      state.tagTrainingImportModalOpened = payload;
    },
    updateTagTrainingImportCsvOrExcelResult(state, { payload }: PayloadAction<ExcelCsvParseResultWithFileInfo>) {
      state.tagTrainingImportCsvOrExcelResult = payload;
    },
    resetTagTrainingImportCsvOrExcelResult(state) {
      state.tagTrainingImportCsvOrExcelResult = {
        rows: [],
        file: null,
        headers: [],
      };
    },
  },
});

export const {
  closeTagIntelligentTagsDrawerAction,
  openTagIntelligentTagsDrawerAction,
  editTagIntelligentTagsDrawerAction,
  setTagTrainingImportModalAction,
  updateTagTrainingImportCsvOrExcelResult,
  resetTagTrainingImportCsvOrExcelResult,
} = IntelligentTagsState.actions;
